@import 'assets/scss/skin';
@import 'assets/fonts/scss/arm.scss';
@import 'assets/fonts/scss/default.scss';
@import 'assets/fonts/icons/icomoon/icons.scss';
@import 'assets/fonts/icons/sport-icons/sport-icons.scss';
@import 'assets/fonts/scss/iran.scss';
@import 'assets/scss/defaults/reset.scss';
@import "structure.scss";
@import 'assets/scss/defaults/date-picker-color.scss';
@import 'assets/scss/defaults/react-datepicker.scss';
@import 'assets/scss/defaults/sprite-flags.scss';
body {
  @import "custom.scss";
}
