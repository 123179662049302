
.top-m:before {
  color: #ff0000;
}

.bot-m:before {
  color: #008000;
}

//start of custom icons part

.AlpineSkiing:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_alpine_skiing.png) no-repeat 50% 50% / contain;
}

.integration-view {
  padding: 24px 0 48px;
}

.AmericanFootball:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_american_football.png) no-repeat 50% 50% / contain;
}

.Archery:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_archery.png) no-repeat 50% 50% / contain;
}

.Athletics:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_athletics.png) no-repeat 50% 50% / contain;
}

.Badminton:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_badminton.png) no-repeat 50% 50% / contain;
}

.Baseball:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_baseball.png) no-repeat 50% 50% / contain;
}

.Basketball:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_basketball.png) no-repeat 50% 50% / contain;
}

.Biathlon:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_biathlon.png) no-repeat 50% 50% / contain;
}

.Bowls:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_bowls.png) no-repeat 50% 50% / contain;
}

.Boxing:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_boxing.png) no-repeat 50% 50% / contain;
}

.Chess:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_chess.png) no-repeat 50% 50% / contain;
}

.Cricket:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_cricket.png) no-repeat 50% 50% / contain;
}

.CounterStrike:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_cs.png) no-repeat 50% 50% / contain;
}

.Curling:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_curling.png) no-repeat 50% 50% / contain;
}

.Cycling:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_cycling.png) no-repeat 50% 50% / contain;
}

.Darts:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_darts.png) no-repeat 50% 50% / contain;
}

.Diving:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_diving.png) no-repeat 50% 50% / contain;
}

.Dota2:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_dota2.png) no-repeat 50% 50% / contain;
}

.Electronic:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_electronic_sports.png) no-repeat 50% 50% / contain;
}

.Entertainment:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_entertainment.png) no-repeat 50% 50% / contain;
}

.Fencing:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_fencing.png) no-repeat 50% 50% / contain;
}

.Fieldhockey:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_field_hockey.png) no-repeat 50% 50% / contain;
}

.Floorball:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_floorball.png) no-repeat 50% 50% / contain;
}

.Soccer:before, .Football:before, .MixedFootball:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_football.png) no-repeat 50% 50% / contain;
}

.Formula1:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_formula1.png) no-repeat 50% 50% / contain;
}

.Freestyle:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_freestyle_wrestling.png) no-repeat 50% 50% / contain;
}

.Golf:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_golf.png) no-repeat 50% 50% / contain;
}

.Gymnastics:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_gymnastics.png) no-repeat 50% 50% / contain;
}

.Handball:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_handball.png) no-repeat 50% 50% / contain;
}

.HorseRacing:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_horse_racing.png) no-repeat 50% 50% / contain;
}

.IceHockey:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_ice_hockey.png) no-repeat 50% 50% / contain;
}

.Live:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_live_all.png) no-repeat 50% 50% / contain;
}

.LeagueOfLegends:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_LoL.png) no-repeat 50% 50% / contain;
}

.Mma:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_mma.png) no-repeat 50% 50% / contain;
}

.Motorsport:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_motorbikes.png) no-repeat 50% 50% / contain;
}

.Netball:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_netball.png) no-repeat 50% 50% / contain;
}

.Olympics:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_olympics.png) no-repeat 50% 50% / contain;
}

.Oscar:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_oscar.png) no-repeat 50% 50% / contain;
}

.Overwatch:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_overwatch.png) no-repeat 50% 50% / contain;
}

.Poker:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_poker.png) no-repeat 50% 50% / contain;
}

.Politics:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_politics.png) no-repeat 50% 50% / contain;
}

.Pool:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_pool.png) no-repeat 50% 50% / contain;
}

.Rowing:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_rowing.png) no-repeat 50% 50% / contain;
}

.RugbyLeague:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_rugby_league.png) no-repeat 50% 50% / contain;
}

.Yachting:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_sailing.png) no-repeat 50% 50% / contain;
}

.SpecialBets:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_special_bets.png) no-repeat 50% 50% / contain;
}

.StarCraft2:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_starcraft2.png) no-repeat 50% 50% / contain;
}

.Sumo:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_sumo.png) no-repeat 50% 50% / contain;
}

.Surfing:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_surfing.png) no-repeat 50% 50% / contain;
}

.Swimming:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_swimming.png) no-repeat 50% 50% / contain;
}

.TableTennis:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_table_tennis.png) no-repeat 50% 50% / contain;
}

.Taekwondo:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_taekwondo.png) no-repeat 50% 50% / contain;
}

.Tennis:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_tennis.png) no-repeat 50% 50% / contain;
}

.virtualdogs:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_vainglory.png) no-repeat 50% 50% / contain;
}

.virtualsports:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_virtual_sports.png) no-repeat 50% 50% / contain;
}

.Volleyball:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_volleyball.png) no-repeat 50% 50% / contain;
}

.WaterPolo:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_water_polo.png) no-repeat 50% 50% / contain;
}

.Weightlifting:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_weightlifting.png) no-repeat 50% 50% / contain;
}

.Wintersports:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_winter_sports.png) no-repeat 50% 50% / contain;
}

.KingOfGlory:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/king_of_glory_icon.png) no-repeat 50% 50% / contain;
}

.RocketLeague:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/icon_rocketleague.png) no-repeat 50% 50% / contain;
}

.RainbowSix:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/esport/Rainbow-Six.png) no-repeat 50% 50% / contain;
}

.Kabaddi:before {
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  background: url(../../assets/esport-images/sport-icons/esport/kabaddi.png) no-repeat 50% 50% / contain;
}

.single-sport-title-dashboard-m:before {
  display: none;
}

.select-sport-title-m:before {
  display: none;
}

.dashboard-sport-icon-m:before {
  width: 16px;
}

//start of  keyboard part
.key-simbols-m > li:nth-child(1) > span:before {
  display: block;
  content: '\e90c';
  font: 24px/38px icomoon;
  color: c(text-3);
}

.key-simbols-m > li:nth-child(2) > span:before, .key-simbols-m > li:nth-child(2) > span:after {
  content: '';
}

// end of keyboard file

.betslip-balance-view-m > ul {
  display: -webkit-flex; //!* Safari *!
  display: flex;
  align-items: center;
  li {
    width: 100%;
  }
}

.sports-navigation-scroll-buttons {
  display: none;
}

.coefficient-game-view {
  ul {
    &.reverseEvents {
      direction: rtl;
      li {
        padding: 0 0 0 1px;
        .single-coefficient-m {
          direction: ltr;
          p {
            text-align: left;
          }
        }
      }
    }
  }
}

.separator-search-result > div:before {
  display: none;
}

.separator-search-result .result-sport-icon:before {
  width: 18px;
}

.asian-view-filters-wrapper-m {
  top: 49px;
}

.game-date-view-m.asian-v {
  top: 118px;
}

.a-competition-contain {
  padding: 0;
}

.asian-view-filters-wrapper-m.active {
  padding: 0 0 58px 0px;
}

.sport-nav-container-m > a {
  padding: 0 18px 0 11px;
}

//bug fixing
.system-select-option {
  position: fixed;
  top: 5px;
}

.clear-all-m:after {
  content: '';
}

.clear-all-m > span {
  line-height: 40px;
  float: right;
}

.clear-all-m:before {
  content: '';
}

.sports-navigation {
  position: -webkit-sticky;
  position: sticky;
  top: 49px;
  z-index: 444;
}

.won-count-m,
.won,
.balance-view-betslip,
.possible-win-coupon > span,
.win-b-result {
  color: #48a584;
}

.asian-competition-t-v {
  padding: 0 7px;
  height: 36px;
}

.asian-competition-t-v.media ul > li {
  padding: 0 0 0 2px;
}

/*.asian-competition-t-v.media li:last-child span {
  padding: 0 16px 0 8px;
}*/
/*.asian-competition-t-v.media ul > li,
.asian-competition-t-v.media ul > li:first-child > span {
  line-height: 36px;
}*/
/*.select-contain-m:before {
  font: 11px/21px icomoon;
  content: "\e90d";
}
.asian-competition-t-v.media li:first-child span:after {
  content: "\e90d";
}*/
.arrow-u-m {
  line-height: 35px;
}

/*.arrow-u-m:before {
  content: "\e90d";
  font-size: 11px;
}*/
/*.asian-competition-t-v.media li:last-child span:last-child:after {
  content: '\e90d';
  line-height: 16px;
}*/
.asian-competition-t-v.media li:last-child span.open:last-child:after {
  transform: rotate(180deg);
}

.asian-competition-t-v.media ul > li > span.clock {
  margin: 0 2px 0 0;
  padding-left: 8px;
}

.asian-competition-t-v.media li span.clock:after {
  display: none;
}

.asian-competition-t-v.media li:first-child span.open:after {
  content: "\e90d";
  float: none;
  transform: rotate(180deg);
  display: inline-block;
  padding: 0 5px 0;
}

.arrow-view-m:before,
div.expandable > .arrow-view-m:before {
  content: "\E90D";
  font-size: 11px;
}

.a-competition-contain .arrow-view-m {
  line-height: 28px;
}

.expandable.opened > .arrow-view-m:before,
div.expandable.opened > .arrow-view-m:before,
.asian-view-filters-wrapper-m.active .arrow-u-m:before {
  content: '\E90D';
  transform: rotate(180deg);
  display: inline-block;
}

.balance-view-betslip:before {
  content: '\e93b';
  font: 17px icomoon;
  color: #a6a6a6;
  margin-right: 8px;
}

& .integration-view .asian-comp-row-v.second-c-p {
  top: 155px;
}

.integration-view .asian-comp-row-v.second-c-p {
  border: 1px solid #ece702;
}

.right-navigate-list-view-m {
  height: auto;
  margin-bottom: 20px;
}

.closed-nav-icon {
  position: static;
  width: auto;
  height: auto;
  margin: auto;
  border: 0;
  color: #000;
  background-color: #48a584;
  padding: 5px 10px;
  font-size: 13px;
  line-height: 30px;
  opacity: 1;
  font-family: inherit;
}

.closed-nav-icon:before {
  display: none;
}

.bbin-rightmenu-balance span {
  color: #48a584;
}

.bbin-rightmenu-balance .balance-c-m {
  width: auto;
  float: right;
}

.deposit-b-total-b > ul {
  height: auto;
}

.deposit-b-total-b > ul > li > p {
  position: relative;
}

.balance-user-view-m .deposit-b-total-b {
  margin: 0;
}

@supports (position: sticky) or (position: -webkit-sticky) {
  .asian-comp-row-v.second-c-p {
    top: 87px;
  }
  .integration-view .asian-comp-row-v.second-c-p {
    top: 154px;
  }
}

.select-sport-title-m {
  position: -webkit-sticky;
  position: sticky;
  top: 115px;
  z-index: 444;
}

.integration-view .game-date-view-m.asian-v {
  top: 185px;
}

.integration-view .asian-view-filters-wrapper-m,
.integration-view .asian-view-filters-wrapper-m.active .asian-filter-picker {
  top: 117px;
}

//old browsers fixed sport menu
@supports not ((position: sticky) or (position: -webkit-sticky)) {
  .asian-view-wrapper {
    padding-top: 69px;
    > .select-sport-title-m {
      position: fixed;
      top: 115px;
      z-index: 66;
    }
    .sports-navigation {
      position: fixed;
      left: 0;
    }

  }
  .integration-view .favorite-game-list .asian-comp-row-v.second-c-p {
    top: 0;
    position: relative;
  }
  .integration-view .favorite-game-list .game-date-view-m.asian-v {
    top: 0;
    position: relative;
  }
}

@media screen and (min-width: 980px) {
  .betslip-full-view, .betslip-full-view.active {
    position: fixed;
  }
}

.a-competition-contain div.expandable > .check-m, .check-m.child-selected:before {
  color: inherit;
}

@media (min-width: 980px) {
  .asian-view-wrapper {
    padding-top: 0;
  }
  .full-a-g-list-w {
    margin-top: 50px;
  }
  .integration-view {
    padding: 0 0 48px 0;
  }
  .a-competition-contain {
    padding-top: 120px;
  }
  .asian-view-filters-wrapper-m.active {
    padding: 0 0 10px 0px;
  }
}

.asian-game-c > .game-information-m.noXMarket > ul:nth-child(even) {
  box-shadow: 0 1px 0 rgba(123, 129, 145, 0.15);
}

.asian-view-games-list-wrapper-m .game-information-m.noXMarket ul > li.teams-name-info-m,
.asian-view-games-list-wrapper-m .game-information-m.noXMarket ul:nth-child(even) > .teams-name-info-m {
  box-shadow: none;
}

.popup-message {
  color: inherit;
}

.single-coefficient-m.active.blockedEvent:first-child {
  background: #e6524f;
}

.date-picker-wrapper .react-datepicker__input-container:before {
  color: inherit !important;
}

.returnedbets:before {
  content: "\E90C";
  font-size: 10px;
}

.returned {
  color: #5ed2f9;
}

.returned:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid #5ed2f9;
}

//@media screen and (max-width: 320px) {
.all-bets-settings-row-m {
  height: 80px;
}

.betslip-full-view.active {
  padding-top: 128px;
}

.all-bets-settings-row-m > ul > li:nth-child(2), .all-bets-settings-row-m > ul > li.cleared-all-box {
  position: absolute;
  top: 85px;
  left: 10px;
}

.all-bets-settings-row-m > ul > li.cleared-all-box {
  right: 10px;
}

.all-bets-settings-row-m > ul > li.system-select-option {
  top: 48px;
  right: 10px;
}

@media screen and (max-width: 320px) {
  .asian-competition-t-v.media ul > li:nth-child(2), .asian-competition-t-v.media ul > li:nth-child(3) {
    display: none;
  }
}

@media only screen and (max-width: 320px) {
  .System .clear-all-m {
    display: block;
  }
}

.bet-balance-top-m {
  float: left;
  width: 100%;
}

.quick-bet-settings-view {
  padding: 0 88px 0 10px;
}

.bet-balance-top-m > .balance-view-betslip {
  padding: 10px;
  line-height: 30px;
}

.switcher-betcloud > span:before {
  display: none;
}

.switcher-betcloud > span {
  color: c(text-4);
}

//add new filters
.title-row-u-m > p > span {
  padding: 0;
}

.asian-filter-picker ul li, .asian-filter-picker ul {
  width: auto;
}

.asian-filter-picker {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

}

.asian-filter-picker::-webkit-scrollbar {
  display: none;
  width: 0;
}

// add future competition styles
.icon-view-u-m.icon-stopwatch {
  content: '\e6e2';
}

.asian-view-wrapper.future .asian-filter-picker ul, .asian-view-wrapper.future .asian-filter-picker ul li {
  width: auto;
}

.asian-view-wrapper.future .title-row-u-m > p > span {
  padding: 0;
}

.asian-view-wrapper.future .asian-filter-picker ul li:last-child {
  // box-shadow: 1px 0 0 rgba(255,255,255,0.09) inset;
  border-left:1px solid c(skin-8);
}

.asian-view-wrapper .asian-filter-picker ul li {
  width: 50%;
}

.asian-view-wrapper .asian-filter-picker ul {
  width: 100%;
}

.asian-view-wrapper.future .asian-filter-picker::-webkit-scrollbar {
  display: none;
  width: 0;
}

.odd-stake-info-m.border-none {
  border-bottom: none;
}

.returned-icon:before {
  content: "\e953";
}

.right-nav-container-m {
  padding: 0;
}

.right-top-nav:after {
  display: none;
}

.right-top-nav {
  width: 48px;
  padding: 0;
  text-align: center;
}

.right-top-nav > span {
  padding: 0;
}

.right-menu-full-box-m {
  padding-top: 48px;
  box-shadow: none;
  box-sizing: border-box;
}

.right-nav-container-m > .closed-nav-icon {
  right: 8px;
  left: inherit;
}

.right-nav-container-m {
  transition: transform .25s linear;
  -webkit-transition: transform .25s linear;
  transform: translateY(-100%);
}

.main-wrapper.rightMenu.rightMenu .right-nav-container-m {
  transform: translateY(10px);
  z-index: 9999;
}

.closed-nav-icon {
  min-width: 165px;
  min-height: 40px;
  width: 165px;
  height: 40px;
}

//make small changes for custom view
.asian-competition-t-v.media li a {
  color: cs(skin-11);
}

.asian-competition-t-v.media ul li > span.clock {
  color: #48a584;
}

.asian-competition-comp-n .asian-comp-row-v {
  background: c(skin-12);
}

.betslip-balance-view-m ul > li > a {
  color: cs(skin-10);
}

.e-single-game-wrapper-a .game-date-view-m > ul > li {
  width: 58px;
}

.game-date-view-m.asian-v {
  .select-contain-m > select {
    height: 24px;
  }
}

/// styles for esport
.integration-view .asian-view-filters-wrapper-m.esport,
.integration-view .asian-view-filters-wrapper-m.esport.active .asian-filter-picker {
  top: 45px;
}

.integration-view .asian-view-filters-wrapper-m.esport.active .asian-filter-picker {
  height: 100%;
}

.esport-nav-container-m {

  a.active {
    > span {
      box-shadow: 0px 0px 10px sc(skin-15, .63);
    }
  }
  .esport-icon-m {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    display: block;
    &:before {
      content: "";
      display: none !important;
    }
    &.CallOfDuty {
      background-image: url(../../assets/esport-images/sport-icons/esport/CallOfDuty.png);
    }
    &.CounterStrike {
      background-image: url(../../assets/esport-images/sport-icons/esport/CounterStrike.png);
    }
    &.Dota2 {
      background-image: url(../../assets/esport-images/sport-icons/esport/Dota2.png);
    }
    &.LeagueOfLegends {
      background-image: url(../../assets/esport-images/sport-icons/esport/LeagueOfLegends.png);
    }
    &.ClashRoyale {
      background-image: url(../../assets/esport-images/sport-icons/esport/clash_royale.png);
    }
    &.Overwatch {
      background-image: url(../../assets/esport-images/sport-icons/esport/Overwatch.png);
    }
    &.StarCraft {
      background-image: url(../../assets/esport-images/sport-icons/esport/StarCraft.png);
    }
    &.StarCraft2 {
      background-image: url(../../assets/esport-images/sport-icons/esport/StarCraft2.png);
    }
    &.CrossFire {
      background-image: url(../../assets/esport-images/sport-icons/esport/CrossFire.png);
    }
    &.Halo {
      background-image: url(../../assets/esport-images/sport-icons/esport/Halo.png);
    }
    &.KingOfGlory {
      background-image: url(../../assets/esport-images/sport-icons/esport/KingOfGlory.png);
    }
    &.MortalKombatXL, .MortalKombat:before {
      background-image: url(../../assets/esport-images/sport-icons/esport/MortalKombat.png);
    }
    &.Overwatch {
      background-image: url(../../assets/esport-images/sport-icons/esport/Overwatch.png);
    }
    &.PUBG {
      background-image: url(../../assets/esport-images/sport-icons/esport/PUBG.png);
    }
    &.RocketLeague {
      background-image: url(../../assets/esport-images/sport-icons/esport/RocketLeague.png);
    }
    &.Smite {
      background-image: url(../../assets/esport-images/sport-icons/esport/Smite.png);
    }
    &.Vainglory {
      background-image: url(../../assets/esport-images/sport-icons/esport/Vainglory.png);
    }
    &.WarcraftIII {
      background-image: url(../../assets/esport-images/sport-icons/esport/WarcraftIII.png);
    }
    &.WorldOfTanks {
      background-image: url(../../assets/esport-images/sport-icons/esport/WorldOfTanks.png);
    }
    &.WorldOfWarcraft {
      background-image: url(../../assets/esport-images/sport-icons/esport/WorldOfWarcraft.png);
    }
    &.HeroesOfTheStorm {
      background-image: url(../../assets/esport-images/sport-icons/esport/HeroesOfTheStorm.png);
    }
    &.Hearthstone {
      background-image: url(../../assets/esport-images/sport-icons/esport/Hearthstone.png);
    }
    &.EBasketball {
      background: url(../../assets/esport-images/sport-icons/esport/E-Basketball.png) no-repeat 50% 50% / contain;
    }
    &.NBA2K {
      background: url(../../assets/esport-images/sport-icons/esport/nba_2k.png) no-repeat 50% 50% / contain;
    }
    &.CyberFootball {
      background: url(../../assets/esport-images/sport-icons/esport/E-Football.png) no-repeat 50% 50% / contain;
    }
    &.ETennis {
      background: url(../../assets/esport-images/sport-icons/esport/E-Tennis.png) no-repeat 50% 50% / contain;
    }
    &.E-IceHockey {
      background: url(../../assets/esport-images/sport-icons/esport/E-Ice-Hokey.png) no-repeat 50% 50% / contain;
    }
    &.RainbowSix {
      background: url(../../assets/esport-images/sport-icons/esport/RainbowSix.png) no-repeat 50% 50% / contain;
    }
  }
}

//
.e-game-information-m .asian-competition-t-v.media li:last-child span {
  font-size: 11px;
  &:after {
    position: absolute;
    right: 0;
    top: 5px;
  }
}

.asian-view-games-list-wrapper-m .e-game-info-mini-m .team-name-m-box > i, .asian-view-games-list-wrapper-m .team-name-m-box > i {
  padding-left: 30px;
  position: relative;
  //&:before{
  //  width: 23px;
  //  height: 29px;
  //  background: url(../../assets/esport-images/sport-icons/esport/empty-g.png) no-repeat 0 0;
  //  content: '';
  //  display: block;
  //  position: absolute;
  //  left: 0;
  //  top: -10px;
  //  background-size: cover;
  //}
}

.asian-view-games-list-wrapper-m .e-game-info-mini-m .team-name-m-box img, .asian-view-games-list-wrapper-m .team-name-m-box img {
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
}

.e-asian-open-v {
  padding: 0 10px 10px;
}

.esport .asian-filter-picker .title-row-u-m.active > .arrow-u-m:before {
  transform: rotate(180deg);
}

.e-asian-comp.second-c-p {
  margin: 0;
}

.e-single-game-wrapper-a {
  padding: 10px 0 0;
}

@media (min-width: 980px) {
  .european-view-wrapper {
    padding-top: 0;
    height: 100%;
  }
  .full-a-g-list-w {
    margin-top: 50px;
  }
  .integration-view {
    padding: 0 0 48px 0;
  }
  .a-competition-contain {
    padding-top: 120px;
  }
  .asian-view-filters-wrapper-m.active {
    padding: 0 0 10px 0px;
  }
}

.european-view-wrapper.future .european-filter-picker ul, .european-view-wrapper.future .european-filter-picker ul li {
  width: auto;
}

.european-view-wrapper.future .title-row-u-m > p > span {
  padding: 0;
}

.european-view-wrapper.future .european-filter-picker ul li:last-child {
  // box-shadow: 1px 0 0 rgba(255,255,255,0.09) inset;
  border-left:1px solid c(skin-8);
}

.european-view-wrapper .european-filter-picker ul li {
  width: 50%;
}

.european-view-wrapper .european-filter-picker ul {
  width: 100%;
}

.european-view-wrapper.future .european-filter-picker::-webkit-scrollbar {
  display: none;
  width: 0;
}

//old browsers fixed sport menu
@supports not (position: sticky) {
  .european-view-wrapper {
    padding-top: 69px;
    > .select-sport-title-m {
      position: fixed;
      top: 115px;
      z-index: 66;
    }
    .sports-navigation {
      position: fixed;
      left: 0;
    }

  }
  .integration-view .favorite-game-list .asian-comp-row-v.second-c-p {
    top: 0;
    position: relative;
  }
  .integration-view .favorite-game-list .game-date-view-m.asian-v {
    top: 0;
    position: relative;
  }
}

/*@supports (-webkit-overflow-scrolling: touch) {
  .european-view-wrapper {
    padding-top: 0;
  }
}*/
.european-view-games-list-wrapper-m .game-information-m.noXMarket ul > li.teams-name-info-m,
.european-view-games-list-wrapper-m .game-information-m.noXMarket ul:nth-child(even) > .teams-name-info-m {
  box-shadow: none;
}

.asian-competition-comp-n .asian-comp-row-v {
  background: c(skin-12);
}

.e-european-open-v {
  padding: 0 10px;
  margin: 0 0 10px;
  .e-single-game-wrapper-a {
    & + .e-single-game-wrapper-a {
      border-top: 1px solid c(skin-14);
    }
    &:last-child {
      border-radius: 0 0 8px 8px;
    }
  }
}

.favorite-star {
  left: 0;
  top: 0;
  width: 34px;
  height: 34px;
  font: 16px/34px icomoon;
  text-align: center;
  color: #131724;
  transform: translate3d(0, 0, 0);
}

.favorite-star:before {
  content: "\f005";
}

.european-game-c {
  display: table;
  table-layout: fixed;
  width: 100%;
}

//European Styles
/*.european_esport_live,
.european_esport_prematch {*/
.bread-crumbs-view-m .back-arrow-crumbs {
  border-right: 1px solid sc(skin-12, .2);
  color: sc(skin-14);
  box-shadow: none;

}

.asian-view-games-list-wrapper-m,
.european-view-games-list-wrapper-m {
  padding-top: 10px;
}

.teams-name-shirt-color {
  border-bottom: 1px solid rgba(c(text-1), .2);
}

.bread-crumbs-view-m {
  background: c(skin-12);
  box-shadow: none;
  p {
    color: sc(skin-14);
  }
}

.esport-nav-container-m > a > p {
  font-size: 10px;
  color: c(text-1);
}

.e-game-information-m .e-factor-m {
  background: none;
  list-style: none;
}

i {
  font-style: normal;
}

.e-factor-m i {
  font-size: 12px;
}

.world-games-title {
  padding: 10px 0;
  font-size: 16px;
  font-weight: 500;
  color: c(text-2);
  .a-title-v {
    color: c(text-2);
    font-size: 12px;
    line-height: 21px;
    font-weight: normal;
  }
}

.asian-view-filters-wrapper-m .asian-filter-picker ul {
  width: 100%;
  .icon-view-u-m.left-top-nav:before {
    content: "\e986";
  }
}

.game-results-m {
  float: left;
  width: 100%;
  padding: 10px;
  font-size: 12px;
  color: c(text-1);
  text-align: center;
}

.e-european-comp.second-c-p {
  margin: 0;
}

.e-single-game-wrapper-a {
  padding: 0;
  background: c(skin-12, .75);
  .game-date-view-m > ul > li {
    max-width: inherit;
  }
}

.european_games_inner {
  display: table;
  width: 100%;
  table-layout: fixed;
  direction: ltr;
  > span {
    width: 15%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  > .teams-name-info-m {
    display: table-cell;
    width: 100%;
    margin: 0;
    padding: 0 10px;
    background-color: c(skin-8);
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    vertical-align: middle;
    overflow: hidden;
    &.active {
      background: c(hero);
      .e-factor-m {
        color: c(text-1);
      }
    }
  }
}

.esport .list-games-m {
  padding: 10px 10px 0;
}

.team-name-m-box {
  vertical-align: middle;
}

.e-game-information-m .e-factor-m .top-m:before,
.e-game-information-m .e-factor-m .bot-m:before {
  top: 2px;
}

.teams-name-info-m {
  &.closed-event {
    .e-factor-m span:before {
      content: "\e68f";
      font: 14px/46px icomoon;
    }
  }
  .team-name-m-box {
    img {
      display: block;
      position: absolute;
      left: 0;
      top: 13px;
      width: 20px;
      height: 20px;
    }
    > i {
      position: relative;
      display: -webkit-box;
      max-height: 32px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      padding-right: 5px;
      padding-left: 25px;
      font-size: 12px;
      white-space: initial;
      word-break: break-word;
    }
  }
  &:first-child {
    .e-factor-m .top-m:before,
    .e-factor-m .bot-m:before {
      left: initial;
      right: -11px;
    }
  }
  &:last-child {
    .e-factor-m .top-m:before,
    .e-factor-m .bot-m:before {
      right: initial;
      left: -11px;
    }
    .team-name-m-box {
      img {
        left: initial;
        right: 0;
      }
      > i {
        text-align: right;
        padding-left: 5px;
        padding-right: 25px;
      }
    }
  }
}

.e-game-information-m .e-factor-m {
  display: table-cell;
}

.versus {
  font-size: 16px;
  font-weight: 500;
  color: c(text-1);
}

.more-markets-wrapper {
  margin-top: 10px;
  padding: 0 0 10px;
  text-align: right;
  > a {
    position: relative;
    font-size: 12px;
    color: c(text-2);
    text-decoration: none;
    &:after {
      content: "\e672";
      position: relative;
      font: 10px/12px icomoon;
      right: 0;
    }
  }
}

.esport .asian-filter-picker .title-row-u-m.active > .arrow-u-m:before,
.esport .asian-competition-t-v.media li:last-child span:last-child:after,
.esport .asian-filter-picker .title-row-u-m .arrow-u-m:before,
.esport .game-date-view-m.asian-v .select-contain-m:before {
  font: 16px/20px icomoon;
  color: c(text-3);
  content: "\e670";
}

.esport .arrow-u-m:before,
.esport .select-contain-m:before,
.esport .expandable > .arrow-view-m:before,
.esport .expandable.opened > .arrow-view-m:before {
  font: 16px/20px icomoon;
  content: "\e671";
  color: c(text-3);
}

.teams-name-shirt-color > ul {
  img {
    min-height: 30px;
    max-height: 30px;
  }
  i {
    font-size: 14px;
    line-height: 30px;
    color: c(text-1);
    font-style: normal;
    vertical-align: top;
  }
  li:nth-child(2) {
    span {
      font-size: 16px;
      line-height: 30px;
      font-weight: 500;
      color: c(hero);
      text-transform: uppercase;
    }
  }
  li:first-child {
    i {
      padding-right: 7px;
    }
    img {
      float: right;
    }
  }
  li:last-child {
    i {
      padding-left: 7px;
    }
  }
}

.game-date-row-m {
  height: 34px;
  p {
    font-size: 12px;
    line-height: 34px;
    &.game-date,
    &.game-time {
      &:after {
        content: "|";
        display: inline-block;
        padding-left: 3px;
        margin-right: 3px;
      }
    }
  }
  .game-countdown {
    float: left;
  }
  .countdown-container {
    font-size: 12px;
    line-height: 34px;
    color: c(hero);
    > span {
      padding-right: 3px;
    }
  }
  .favorite-star {
    width: 40px;
    height: 34px;
    line-height: 34px;
  }
}

.game-view-wrapper {
  padding: 10px;
  .game-view-title-contain-m {
    background: c(skin-12);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
}

.game-markets-f {
  background: c(skin-12);
  box-shadow: none;
  .sport-icon-m.favorites:before {
    font-size: 16px;
    color: rgba(c(text-1), .6);
  }
  > ul {
    display: initial;
    padding: 0;
  }
  > ul > li {
    padding: 0 13px;
    line-height: 44px;
    background: c(skin-12);
    color: c(text-1);
    text-transform: uppercase;
    &:first-child {
      border-right: 1px solid rgba(c(text-1), .2);
    }
    &.active {
      color: c(hero);
      background: c(skin-12);
      box-shadow: none;
    }
  }
}

.name-market-contain-cell-m {
  > i {
    font-size: 14px;
    color: c(text-1);
  }
  .cash-out-icon-view-m {
    position: absolute;
    right: 52px;
  }
}

.singe-market-view-m .single-market-title-m {
  padding: 0 30px;
  background: transparent;
  .markets-closed-open-arrow-m {
    width: 20px;
    color: rgba(c(text-1), .6);
  }
  .fav-star-m {
    left: initial;
    width: 20px;
    right: 25px;
    z-index: 9;
    height: 36px;
    line-height: 36px;
    &.active {
      background: none;
      color: c(hero);
    }
    &.active:before {
      color: c(hero);
    }
  }
}

.markets-closed-open-arrow-m {
  color: c(text-1);
}

.fav-star-m {
  &:before {
    font-size: 16px;
    color: rgba(c(text-1), .6);
  }
  &.active:before {
    color: c(hero);
  }
}

.all-markets-view-m {
  padding: 0 10px 7px;
  box-shadow: none;
}

.singe-market-view-m {
  padding: 0 10px;
  background: c(skin-12);
  border-radius: 8px;
}

.single-coefficient-m {
  background-color: c(skin-8);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  > p {
    color: c(text-1);
  }
  > span {
    font-size: 12px;
  }
  &.active {
    background: c(hero);
    color: sc(hero);
    > span {
      color: sc(hero);
    }
    .top-m:before {
      color: #ff0000;
    }
    .bot-m:before {
      color: #008000;
    }
  }
}

.coefficient-game-view > ul {
  margin: 1px 0 10px;
  > li {
    background: none;
    &:first-child {
      padding-right: 2px;
    }
    &:last-child {
      padding-left: 2px;
    }
  }
}

.game-date-row-m {
  border-bottom: none;
  > p {
    color: c(text-1);
  }
  .favorite-star {
    font-size: 16px;
    color: rgba(c(text-1), .6);
  }
}

.market-group-title-m > h3 {
  font-size: 14px;
  color: c(text-1);
}

.asian-view-filters-wrapper-m .import-view-container {
  bottom: 46px;
}

.asian-view-filters-wrapper-m.active {
  background: none;
}

.game-results-wrapper {
  padding: 10px;
  .game-filters {
    float: left;
    width: 100%;
    .details-form-item-m {
      display: inline-block;
      width: 50%;
      margin-bottom: 15px;
      &:nth-child(odd) {
        padding-right: 5px;
      }
      &:nth-child(even) {
        padding-left: 5px;
      }
      &:nth-last-of-type(2) {
        .date-picker-wrapper {
          .react-datepicker-popper {
            transform: translate3d(30%, 147px, 0px) !important;
            .react-datepicker__triangle {
              left: initial;
              right: 50px;
            }
          }
        }
      }
      > label {
        font-size: 11px;
        color: c(text-2);
      }
      .select-contain-m {
        width: 100%;
        &:before {
          font: 18px/18px icomoon;
          content: "\E670";
          color: rgba(c(text-1), .6);
          border: 0;
        }
      }
      &:last-child {
        width: 100%;
        padding: 0 0 15px;
        border-bottom: 1px solid rgba(c(text-1), .2);
      }
      select {
        width: 100%;
        font-size: 12px;
        color: c(text-2);
        border-radius: 8px;
        border-color: rgba(c(text-1), .1);
      }
      .date-picker-button-view-custom {
        margin-top: 5px;
        padding-bottom: 7px;
        font-size: 12px;
        color: c(text-2);
        border-radius: 8px;
        border-color: rgba(c(text-1), .1);
      }
      .button-view-normal-m {
        line-height: 42px;
        font-size: 12px;
        color: c(text-1);
        border-radius: 8px;
        background-color: c(skin-8);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
  .filtered-games-result {
    float: left;
    width: 100%;
    .filtered-result {
      float: left;
      width: 100%;
      margin-bottom: 10px;
      .main-info {
        float: left;
        width: 100%;
        padding: 10px;
        background: c(skin-12);
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        .date-event-type,
        .game-event-type {
          display: table;
          width: 100%;
          table-layout: fixed;
          color: c(text-2);
          span {
            display: table-cell;
            &:last-child {
              text-align: right;
            }
          }
        }
        .date-event-type {
          font-size: 14px;
          margin-bottom: 3px;
        }
        .game-event-type {
          font-size: 12px;
        }
      }
    }
    .filtered-games-result {
      margin-top: 10px;
      padding: 10px;
      background: c(skin-10);
      border-radius: 8px;
      border-top: 1px solid rgba(c(text-1), .2);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      .game-result-data {
        display: table;
        width: 100%;
        table-layout: fixed;
        margin-bottom: 7px;
        > span {
          display: table-cell;
          font-size: 12px;
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }
}

// Asian view
.asian_esport_live {
  .e-game-information-m .e-factor-m .top-m:before {
    left: -12px;
  }
}

.asian-game-c .e-game-information-m .e-factor-m .top-m:before,
.asian-game-c .e-game-information-m .e-factor-m .bot-m:before {
  top: -6px;
  left: -13px;
}

.asian-competition-t-v {
  padding: 0;
}

.european-competition-t-v.media span.tv,
.asian-competition-t-v.media li span.tv {
  padding: 0;
  margin-right: 5px;
  &:after {
    content: "\e995";
    position: relative;
    right: initial;
    top: initial;
    display: inline-block;
    font: 12px/12px Icomoon;
    color: rgba(c(text-1), .6);
    vertical-align: middle;
  }
}

.european-competition-t-v.media {
  font-size: 12px;
  span.tv:after {
    padding-left: 5px;
    vertical-align: initial;
  }
}

.asian-view-games-list-wrapper-m .team-name-m-box > i {
  display: inline-block;
  overflow: hidden;
  max-width: 121px;
  max-height: 32px;
  height: auto;
  text-overflow: ellipsis;
}

.asian-view .title-row-u-m {
  padding: 0 18px 0 32px;
}

.esport .game-date-view-m.asian-v > ul > li:first-child,
.e-game-information-m > ul > li:first-child {
  width: calc(100% - 182px);
}

/*.e-single-coefficient-m {
  height: 42px;
}*/
.esport .game-date-view-m.asian-v > ul > li,
.asian-game-c > .e-game-information-m .e-factor-m {
  width: 58px;
}

// global
.asian-view-wrapper {
  height: 100%;
}

.navigation-container-m:after {
  display: none;
}

.esport-nav-container-m > a > p {
  text-align: center;
}

.right-menu-full-box-m {
  background-color: c(skin-14);
}

.single-coefficient-m > p > b {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

.game-info-mini-m > p {
  padding: 0;
}

i.event-text-v-b {
  display: -webkit-box;
  max-height: 32px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  padding-right: 5px;
  white-space: initial;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.asian-filter-picker ul {
  display: table;
  table-layout: fixed;
  > li {
    display: table-cell;
  }
}

.import-view-container .select-sport-title-m.favorites {
  top: 44px;
}
.esport-nav-container-m .games-count-view-m {
  padding: 3px 5px;
  border-radius: 5px;
}
.right-top-nav {
  width: 54px;
  height: 44px;
  box-shadow: 0 -1px 0 1px sc(header, .6);
  > span {
    display: inline-block;
    width: 100%;
    font: 16px/45px Icomoon;
    color: sc(header);
    text-align: center;
    &:before {
      content: "\e99a";
      display: inline-block;
    }
  }
}

.cash-out-icon-view-m:before {
  right: 52px;
}

.game-statistic-view > table tr:first-child td,
.game-statistic-view {
  background: c(skin-12);
}

.asian-competition-t-v.media li:last-child span {
  padding: 0;
}

.game-statistic-view > table tr:first-child td {
  font-size: 11px;
  color: c(text-2);
  border-bottom: 1px solid rgba(c(text-1), .2);
  box-shadow: none;
}

.asian-competition-t-v.media ul > li {
  line-height: 20px;
}

.e-game-info-mini-m > p {
  padding: 0;
}

.esport .asian-competition-t-v.media li.more-markets-wrapper {
  border-bottom: none;
}

.esport .asian-competition-t-v.media li.more-markets-wrapper span:last-child:after {
  content: "";
}

div.more-markets-wrapper a span i {
  padding-left: 5px;
}

.asian-view-games-list-wrapper-m .e-game-info-mini-m .team-name-m-box img,
.asian-view-games-list-wrapper-m .team-name-m-box img,
.teams-name-info-m .team-name-m-box img {
  display: block;
  position: absolute;
  left: 0;
  top: 13px;
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.asian-competition-t-v.media li span.clock {
  color: c(text-1);
}

.asian-filter-picker ul li, .asian-filter-picker ul {
  width: 100%;
}

.game-markets-f > ul,
.integration-view .time-filter-m > ul {
  padding: 0;
}

.nav-bar-contain-m > ul > li {
  font-size: 12px;
  line-height: 44px;
}

.game-results-icon {
  display: inline-block;
  font: 20px/44px Icomoon;
  color: sc(header);
  &:before {
    content: "\e9a7";
  }
}

.esport .fav-star-m {
  &.active {
    background: none;
    color: c(hero);
  }
}

.icon-view-u-m {
  color: rgba(c(text-1), .6);
}

.all-markets-view-m {
  box-shadow: none;
}

.icon-view-u-m.icon-stopwatch {
  line-height: 33px;
  &:before {
    content: "\e999";
    display: inline-block;
    font: 9px/12px Icomoon;
    color: rgba(c(text-1), .6);
  }
}

.market-group-title-m {
  background: c(skin-12);
;
}

.european-view-wrapper {
  height: 100%;
}

.content-m {
  height: 100%;
}

.full-a-g-list-w {
  height: 100%;
}

.favorite-star.active {
  background: none;
  color: c(hero);
}

.european-competition-t-v {
  position: relative;
  margin-bottom: 5px;
  color: c(text-2);
  line-height: 20px;
  > .clock {
    font-size: 12px;
  }
}

.favorite-star {
  position: absolute;
  left: initial;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  font: 16px/20px icomoon;
  color: rgba(c(text-1), .8);
}

.sport-icon-m.favorites {
  width: 20px;
  height: 40px;
  padding: 4px 0 0;
}

.sport-icon-m.favorites:before {
  font: 28px/28px icomoon;
}

.esport-nav-container-m > a.active {
  .sport-icon-m.favorites {
    background-color: c(hero);
    &:before {
      color: c(text-1);
    }
  }
}

.asian-view .asian-view-wrapper.future .asian-filter-picker ul, .asian-view .asian-view-wrapper .asian-filter-picker ul {
  width: 100%;
}

.european-view-games-list-wrapper-m,
.european-competition-comp-n,
.european-open-v,
.european-view-games-list-wrapper-m .esport {
  float: left;
  width: 100%;
}

.navigation-container-m {
  background: c(header);
  .nav-bar-contain-m ul {
    direction: ltr;
    > li {
      margin: 0;
      border-right: 1px solid rgba(c(text-1), .2);
      > a {
        min-width: 102px;
        padding: 0 20px;
        color: sc(header);
        text-align: center;
        &.active {
          background: c(header-hover);
        }
      }
      &:last-child > a {
        min-width: initial;
        height: 44px;
        padding: 0 15px;
      }
    }
  }
}

.date-picker-wrapper {
  margin: 0;
  .react-datepicker__day--disabled {
    color: c(text-8) !important;
  }
  .react-datepicker__input-container {
    &:before {
      top: 5px;
      color: rgba(c(text-1), .6) !important;
    }
  }
}

// Asian new styles
/*.e-asian-open-v {
  margin-top: 10px;
}*/
.e-european-comp.second-c-p,
.e-asian-comp.second-c-p {
  height: initial;
  padding: 0 10px;
  border-bottom: 1px solid rgba(c(text-2), .2);
  background: c(skin-12, .9);
  border-radius: 8px 8px 0 0;
  &.Dota2 {
    background: linear-gradient(to right, #9b1013 0%, c(skin-14) 100%);
    &:before {
      display: none;
    }
  }
  &.LeagueOfLegends {
    background: linear-gradient(to right, #01586c 0%, c(skin-14) 100%);
    &:before {
      display: none;
    }
  }
  &.CounterStrike {
    background: linear-gradient(to right, #db7c24 0%, c(skin-14) 100%);
    &:before {
      display: none;
    }
  }
  &.CallOfDuty {
    background: linear-gradient(to right, #564a37 0%, c(skin-14) 100%);
    &:before {
      display: none;
    }
  }
  &.CyberFootball {
    background: linear-gradient(to right, #308231 0%, c(skin-14) 100%);
    &:before {
      display: none;
    }
  }
  &.Hearthstone {
    background: linear-gradient(to right, #9d7337 0%, c(skin-14) 100%);
    &:before {
      display: none;
    }
  }
  &.HeroesOfTheStorm {
    background: linear-gradient(to right, #656292 0%, c(skin-14) 100%);
    &:before {
      display: none;
    }
  }
  &.StarCraft {
    background: linear-gradient(to right, #468168 0%, c(skin-14) 100%);
    &:before {
      display: none;
    }
  }
  &.StarCraft2 {
    background: linear-gradient(to right, #00669f 0%, c(skin-14) 100%);
    &:before {
      display: none;
    }
  }
  &.KingOfGlory {
    background: linear-gradient(to right, #73588e 0%, c(skin-14) 100%);
    &:before {
      display: none;
    }
  }
  &.EBasketball {
    background: linear-gradient(to right, #b04913 0%, c(skin-14) 100%);
    &:before {
      display: none;
    }
  }
  &.ETennis {
    background: linear-gradient(to right, #829d24 0%, c(skin-14) 100%);
    &:before {
      display: none;
    }
  }
  &.MortalKombatXL {
    background: linear-gradient(to right, #a76812 0%, c(skin-14) 100%);
    &:before {
      display: none;
    }
  }
  &.Overwatch {
    background: linear-gradient(to right, #f68720 0%, c(skin-14) 100%);
    &:before {
      display: none;
    }
  }
  &.NBA2K {
    background: linear-gradient(to right, #b4151a 0%, c(skin-14) 100%);
    &:before {
      display: none;
    }
  }
  &.ClashRoyale {
    background: linear-gradient(to right, #2458a8 0%, c(skin-14) 100%);
    &:before {
      display: none;
    }
  }
}

.world-games-title {
  padding: 3px 0;
  font-size: 16px;
  font-weight: 500;
  color: c(text-2);
  .a-title-v {
    color: c(text-2);
    font-size: 12px;
    line-height: 21px;
    font-weight: normal;
  }
}

.e-single-game-wrapper-a {
  padding: 0;
  background: c(skin-12, .75);
  .e-single-game-wrapper-a {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
}

.asian-competition-t-v.media,
.esport .game-date-view-m {
  background: c(skin-12, .1);
}

.title-row-u-m > p {
  height: initial;
}

.asian-game-c > .e-game-information-m .e-factor-m,
.asian-game-c > .e-game-information-m > ul > li {
  background-color: c(skin-8);
  border-radius: 8px;
}

.asian-game-c .asian-competition-t-v.media > ul {
  padding: 0 0 7px;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
}

.esport .game-date-view-m.asian-v > ul > li,
.esport .game-date-view-m.asian-v .select-contain-m > select {
  color: c(text-2);
}

.e-single-coefficient-m.active {
  background: c(hero) ;
  border-radius: 8px;
  i {
    color: sc(hero) !important;
  }
}

.e-single-coefficient-m.active > span > .coef {
  color: c(text-1);
}

.e-game-information-m .asian-competition-t-v.media li:last-child span {
  font-size: 12px;
  color: c(text-2);
}

.e-asian-open-v .flag-view-m {
  display: none;
}

/*.asian-view .asian-view-wrapper .asian-filter-picker ul li {
  padding: 0 5px;
}*/
.asian-filter-picker ul .icon-view-u-m.left-top-nav:before {
  content: "\E986";
}

.title-row-u-m > p {
  font-size: 12px;
  color: c(text-1);
}

.betslip-balance-view-m > ul > li {
  font-size: 12px;
  color: c(text-1);
}

.balance-view-betslip {
  &:before {
    color: c(text-3);
  }
  i {
    line-height: 12px;
    color: c(text-1);
    vertical-align: text-top;
  }
}

.content-m > .import-view-container {
  height: 100%;
}

.markets-closed-open-arrow-m {
  font-size: 16px;
}

.markets-list-wrapper {
  margin-top: 10px;
  padding: 0 10px;
  box-shadow: none;
  .market-group-title-m {
    border-radius: 8px 8px 0 0;
    .markets-closed-open-arrow-m {
      color: rgba(c(text-1), .6);
      font-size: 20px;
      &:before {
        content: "\E670";
      }
    }
    &.active {
      .markets-closed-open-arrow-m:before {
        content: "\E671";
      }
    }
    > h3 {
      font-size: 12px;
      color: rgba(c(text-1), .7);
    }
  }
  .all-markets-view-m {
    padding: 0;
    border-left: 0;
    border-right: 0;
    .singe-market-view-m {
      border-radius: 0;
      margin: 0;
      box-shadow: 0 -1px 0 rgba(255, 255, 255, .06) inset;
      &:last-child {
        box-shadow: none;
        border-radius: 0 0 8px 8px;
      }
    }
    .single-market-title-m {
      padding: 0;
      box-shadow: none;
    }
  }
}

.betslip-title-mini > b {
  width: 24px;
  height: 23px;
  top: -3px;
  padding: 3px 5px;
  border-radius: 8px;
  background: c(hero);
  color: sc(hero);
}

.integration-view .asian-view-filters-wrapper-m.esport {
  top: 44px;
}

.select-contain-m:before {
  color: c(text-2);
}

.main-wrapper.integration-view {
  padding: 0;
  .integration-view {
    padding: 48px 0;
  }
}

.esports-navigation .sport-icon-m.favorites {
  width: 40px;
  padding: 0;
  &:before {
    font: 24px/40px icomoon;
  }
}

.animation-video-wrapper {
  margin-top: 10px;
  padding: 0 10px;
  .animation-v-nav-view {
    padding: 0 37px;
    height: 34px;
    border-radius: 8px 8px 0 0;
    box-shadow: none;
    background: c(skin-12);
    &:before {
      position: absolute;
      left: 10px;
      top: 0;
      content: "\e979";
      display: block;
      font: 16px/34px icomoon;
      color: rgba(c(text-1), .7);
      vertical-align: middle;
    }
  }
  .video-animation-button-m {
    padding: 0;
    border-right: 0;
    text-align: left;
    line-height: 34px;
    color: rgba(c(text-1), .7);
  }
  .game-video p {
    color: rgba(c(text-1), .7);
  }
  .arrow-toggle-view {
    height: 34px;
    font: 20px/34px icomoon;
    color: rgba(c(text-1), .7);
  }
}

.statistics-wrapper {
  float: left;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 10px;
  .animation-stat-tabs {
    height: 34px;
    margin: 0;
    border-radius: 8px 8px 0 0;
    box-shadow: none;
    background: c(skin-13);
    .view-game-additional-info {
      font-size: 12px;
      color: rgba(c(text-1), .7);
      &:first-child {
        border: none;
      }
      &.one:before {
        height: 34px;
        font: 16px/34px icomoon;
        color: rgba(c(text-1), .7);
        background: none;
        box-shadow: none;
      }
    }
  }
  .game-view-title-contain-m.additional-info {
    margin: 0;
    background: c(skin-12, .9);
    box-shadow: none;
    border-radius: 0 0 8px 8px;
    text-align: center;
    border-top: 1px solid rgba(c(text-1), .2);
    .additional-game-info {
      line-height: 24px;
      color: rgba(c(text-1), .7);
    }
  }
  .live-game-stats {
    margin: 0;
  }
  .live-game-score.score-CounterStrike {
    background: c(skin-12);
  }
}

.empty-text-wrapper-m > p {
  color: rgba(c(text-1), .7);
}

.parent-games-button {
  float: left;
  width: 100%;
  margin-top: 10px;
  padding: 0 10px;
  > .game-rules-button,
  .traditional-sportsbook-button {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 42px;
    background-color: c(skin-7);
    color: c(text-3);
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
  }
}

// Right Menu
.asian-view-filters-wrapper-m .import-view-container {
  z-index: 5555;
}

.asian-filter-picker {
  .title-row-u-m {
    .arrow-u-m {
      right: 5px;
    }
    .icon-view-u-m {
      left: 8px;
    }
  }
}

.rightMenu.main-wrapper {
  .userMenu {
    position: relative;
    min-height: 44px;
    > div {
      float: left;
      width: 100%;
    }
    .right-menu-close-icon {
      position: absolute;
      top: 1px;
      right: 10px;
      /*float: right;
      margin-right: 10px;*/
      font: 18px/48px icomoon;
      color: c(text-2);
      z-index: 9;
      &:before {
        content: "\E90C";
      }
    }
  }
  .integration-view {
    padding: 0 0 48px;
  }
  .right-menu-full-box-m {
    padding-top: 0;
  }
  .user-icon-m,
  .user-name-title-m {
    color: c(text-1);
  }
  .bbin-rightmenu-balance {
    float: left;
    width: 100%;
  }
  .select-contain-m {
    border-radius: 3px;
  }
}

.bbin-rightmenu-balance span {
  color: c(text-1);
}

.user-name-contain-m {
  background-color: c(skin-10);
}

.balance-user-view-m {
  background: none;
}

.total-balance {
  float: left;
  width: 100%;
  min-height: 40px;
  margin-bottom: 10px;
  > p > span {
    display: block;
    line-height: 20px;
    &:last-child {
      float: left;
      width: 100%;
    }
  }
}

.total-bonus-money {
  float: left;
  width: 100%;
  min-height: 18px;
  margin-bottom: 3px;
  .total-bonus-title,
  .balance-c-m {
    display: inline-block;
    color: #c3953c;
  }
  .balance-c-m {
    float: right;
    width: auto;
  }
}

.total-withdraw {
  float: left;
  width: 100%;
  min-height: 18px;
  .total-withdraw-title,
  .balance-c-m {
    display: inline-block;
    color: #1a7051;
  }
  .balance-c-m {
    float: right;
    width: auto;
  }
}

.right-navigate-list-view-m .language-container-m {
  border-top: 0;
  border-color: rgba(c(text-1), .2);
  ul {
    padding: 0 10px;
    .select-contain-m {
      > select {
        min-width: 100px;
        border-color: c(text-3);
        color: c(text-3);
      }
      &:before {
        color: c(text-3);
        border-color: c(text-3);
      }
    }
    > li > p {
      color: c(text-3);
    }
  }
}

// for markets
.all-markets-view-m .singe-market-view-m {
  .single-market-title-m {
    min-height: 36px;
    height: auto;
    .name-market-contain-cell-m {
      vertical-align: middle;
      > i {
        position: relative;
        line-height: 22px;
        white-space: initial;
        text-overflow: initial;
        overflow: initial;
        padding-right: 45px;
      }
    }
  }
}

.a-competition-contain .arrow-view-m {
  width: 30px;
}

// Game Rules
.game-rules-wrapper {
  padding: 10px 10px 0;
  background-color: c(skin-14);
  .game-rule {
    position: relative;
    max-height: 56px;
    padding: 10px;
    background: c(skin-12);
    font-size: 14px;
    line-height: 18px;
    color: c(text-2);
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    .rule-text {
      max-width: 80%;
      font-size: 12px;
      max-height: 20px;
      overflow: hidden;
      p:first-child {
        max-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .rule-title {
      max-width: 80%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &:after {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      content: "\e672";
      display: inline-block;
      font: 18px icomoon;
      color: rgba(c(text-1), .6);
    }
    &.active {
      max-height: initial;
      .rule-text {
        max-width: 95%;
        max-height: initial;
        overflow: visible;
        p:first-child {
          max-height: initial;
          text-overflow: initial;
          overflow: initial;
          white-space: initial;
        }
      }
      &:after {
        content: "\E671";
        right: 10px;
        top: 19px;
        transform: translateY(0);
      }
    }
  }
}

.teams-name-shirt-color .team-info-inner {
  display: table;
  vertical-align: middle;
  &.fr {
    float: right;
  }
  img,
  i {
    display: table-cell;
    line-height: 18px;
    vertical-align: middle;
    word-break: break-word;
  }
}

.new-bg {
  position: fixed;
  top: 82px;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.asian-view-filters-wrapper-m .title-row-u-m {
  padding: 0 18px 0 32px;
}

.icon-view-u-m {
  width: 20px;
  padding: 0;
}

.arrow-u-m {
  width: 18px;
  padding: 0;
}

.icon-view-u-m {
  font-size: 14px;
}

.asian-filter-picker .left-top-nav:before {
  font-size: 12px;
}

// asianGamesList
.asian-view-wrapper {
  float: left;
  width: 100%;
}

.asian-view-wrapper > .line-loader + .select-sport-title-m,
.asian-view-wrapper > .line-loader + .select-sport-title-m ~ .line-loader {
  display: none;
}

.asian-view-games-list-wrapper-m.scrolled {
  z-index: 99999;
}

.asian-view-games-list-wrapper-m > .ReactVirtualized__Collection > div {
  overflow: initial !important;
  position: relative;
  background: c(skin-12);
}

.asian-view-games-list-wrapper-m > .ReactVirtualized__Collection {
  position: absolute !important;
  top: -104px;
  padding-top: 104px;
}

.asian-view-games-list-wrapper-m.scrolled > .ReactVirtualized__Collection > div {
  margin-top: -104px;
}

.a-filter-nav-wrapper {
  float: left;
  width: 100%;
  position: relative;
  z-index: 44;
}

.asian-view-games-list-wrapper-m {
  position: relative;
}

.full-a-g-list-w {
  float: left;
  width: 100%;
}

.game-date-view-m.asian-v {
  height: 36px;
  top: 116px;
  z-index: 111;
  > ul > li {
    vertical-align: top;
    line-height: 24px;
    /*box-shadow: 0 1px 0 rgba(255, 255, 255, 0.06) inset;*/
    max-width: 56px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.asian-competition-t-v {
  float: left;
  width: 100%;
  height: 28px;
  padding: 0 10px;
  margin: 0 0 1px;
  background: c(skin-8);
  box-shadow: 0 1px 0 rgba(123, 129, 145, 0.15) inset, 0 1px 0 c(skin-14);
  position: relative;
  > h3 {
    float: left;
    width: 100%;
    font-size: 12px;
    line-height: 28px;
    color: c(text-5);
    font-weight: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.single-game-list-item-m.asian-game-template {
  height: auto;
}

.swiping_style {
  position: relative;
  left: -56px;
}

.asian-game-c {
  display: table;
  table-layout: fixed;
  width: 100%;
  //width: calc(100% + 56px);
  > .game-information-m {
    height: auto;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    float: none;
    > ul {
      width: 100%;
      height: auto;
    }
    &:first-child {
      //width: 56px;
      background: c(skin-11);
      box-shadow: 0 1px 0 c(text-16, .15) inset;
      transition: background 240ms, color 240ms;
      > ul {
        > li {
          // width: 56px;
          background: none;
          //box-shadow: none;
        }
      }
    }
  }
}

.asian-v .title-row-u-m {
  padding: 0 38px 0 10px;
  background: inherit;

}

.single-game-wrapper-a {
  float: left;
  width: 100%;
  > .line-loader {
    display: none;
  }
  &:first-child > .single-game-wrapper-a > .line-loader {
    display: block;
  }
}

.asian-competition-comp-n > .single-game-wrapper-a > .line-loader {
  display: none;
}

.asian-competition-comp-n:first-child > .single-game-wrapper-a > .line-loader {
  display: block;
}

.asian-view-filters-wrapper-m {
  float: left;
  width: 100%;
  background: c(skin-12);
}

.asian-view-games-list-wrapper-m {
  float: left;
  width: 100%;
}

.asian-view-games-list-wrapper-m .game-info-mini-m > p {
  vertical-align: middle;
  height: 100%;
}

.asian-view-games-list-wrapper-m .team-name-m-box > i {
  position: relative;
  left: inherit;
  top: inherit;
  vertical-align: top;
  display: table-cell;
  height: 100%;
  white-space: pre-wrap;
  overflow: inherit;
}

.asian-view-games-list-wrapper-m .team-name-m-box {
  vertical-align: middle;
}

/*.asian-game-c.game-information-m > ul > li:first-child:nth-last-child(4){
  overflow: hidden;
}*/
.asian-game-c .game-score-live-m {
  vertical-align: middle;
}

/*.asian-game-c > .game-information-m > .no-games-text-m{margin: 0;}*/

/*.asian-game-c > .game-information-m.selected:first-child{
  background: #c3953c;
}*/
/*.asian-game-c > .game-information-m.selected li.fav-game-icon-m:before{
  color: #ffffff;
}*/
.asian-game-c .coef {
  display: block;
  width: 100%;
  vertical-align: top;
  font-size: 11px;
  line-height: 30px;
  float: left;
  color: c(text-5);
}

.asian-view-games-list-wrapper-m .game-information-m > ul > li.factor-m > .single-coefficient-m > span {
  display: table-cell;
  line-height: 30px;
  vertical-align: middle;
  white-space: normal;
  position: relative;
}

.asian-view-games-list-wrapper-m .game-information-m > ul > li.factor-m > .single-coefficient-m.blockedEvent > span {
  display: none;
}
.asian-view-games-list-wrapper-m .game-information-m > ul > li.factor-m > .single-coefficient-m.active {
  background: c(hero);
}

.asian-view-games-list-wrapper-m .game-information-m > ul > li.factor-m {
  background: c(skin-12);
}

/*.asian-view-games-list-wrapper-m .game-information-m > ul > li.factor-m > .single-coefficient-m > span > i:before{
  line-height: 30px;
}*/
.asian-game-c > .game-information-m > ul.draw-asian, .game-information-m > ul.draw-asian > li,
.game-information-m > ul.draw-asian > li.factor-m > .single-coefficient-m {
  height: 30px;
  line-height: 28px;
}





.asian-view-games-list-wrapper-m .game-information-m ul:nth-child(even) > .teams-name-info-m, .asian-view-games-list-wrapper-m .game-information-m ul:last-child > .teams-name-info-m {
  box-shadow: 0 1px 0 rgba(123, 129, 145, 0.15) inset;
}

.asian-view-games-list-wrapper-m .game-information-m ul:first-child > .teams-name-info-m {
  box-shadow: 0 1px 0 rgba(123, 129, 145, 0.15) inset;
}

.asian-view-games-list-wrapper-m .game-information-m ul:nth-child(2) > .teams-name-info-m {
  box-shadow: none;
}

.asian-game-c .draw-asian .coef {
  display: none;
}

.asian-competition-t-v.media {
  background: c(skin-11);
  position: static;
  display: block;
}

.asian-competition-t-v.media ul {
  //float: left;
  width: 100%;
  display: table;
  table-layout: auto;
  > li {
    // float: left;
    line-height: 28px;
    text-align: left;
    position: relative;
    padding: 0 5px 0 0;
    display: table-cell;
    vertical-align: top;
    white-space: nowrap;
    width: 1%;
    &:first-child {
      width: auto;
    }
    &:first-child > span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 28px;
    }
    > span {
      font: 11px/15px $font-f;
      position: relative;
      display: inline-block;
      color: c(text-4);
    }
    > span.clock {
      color: #1a7051;
      margin: 0 10px 0 0;
    }
    > span.tv {
      color: c(skin-5);
      text-transform: uppercase;
      padding: 0 20px 0 0;
    }
  }
}

.asian-competition-t-v.media li a {
  text-decoration: none;
  font-size: 11px;
}

.asian-competition-t-v.media span.tv:after, .asian-competition-t-v.media li:last-child span:after {
  display: block;
  font: 13px/15px icomoon;
  position: absolute;
  top: 0;
  right: 0;
}

.asian-competition-t-v.media li:last-child span {
  padding: 0 12px 0 10px;
  color: c(text-5);
}

.asian-competition-t-v.media li span.clock {
  color: #48a584;
}

.asian-competition-t-v.media span.tv:after {
  content: '\e901';
}

.asian-competition-t-v.media li:first-child span:after {
  content: "\E670";
  font: 12px/28px icomoon;
  padding: 0 0 0 5px;
  // float: right;
}

.asian-competition-t-v.media li:first-child span.open:after {
  content: "\E671";
  font: 12px/18px icomoon;
  padding: 0 0 0 5px;
  float: right;
}

//.asian-competition-t-v.media li:last-child span.open:after{
//  content: "\E671";
//}
.asian-competition-t-v.media li:last-child {
  //float: right;
}

.asian-competition-t-v.media {
  border-top: 1px solid c(skin-12);
  box-shadow: 0 1px 0 rgba(123, 129, 145, 0.15) inset;
}

.game-date-view-m.asian-v .select-contain-m > select {
  border: none;
  float: left;
  width: 100%;
  min-width: auto;
  padding-right: 23px;
}

.game-date-view-m.asian-v .title-row-u-m {
  padding: 2px 0 0 5px;
  position: static;
  z-index: 111;
}

.asian-view-wrapper .game-date-view-m.asian-v .arrow-u-m {
  display: none;
}

.asian-competition-reg-n, .asian-competition-comp-n {
  float: left;
  width: 100%;
}

.asian-comp-row-v {
  float: left;
  width: 100%;
  height: 30px;
  padding: 0 30px 0 10px;
  margin: 0 0 1px;
  background: c(skin-8);
  box-shadow: 0 1px 0 rgba(123, 129, 145, 0.15) inset, 0 1px 0 c(skin-14);
  position: relative;
  top: 0;
  font-weight: normal;
  display: block;
  //release version
  &.second-c-p {
    top: 0;
  }

  //new home page version
  //&.second-c-p{
  //  //top: 136px;
  //  top: 98px;
  //  z-index: 199;
  //}

  &.active {
    > .arrow-view-open-box:before {
      content: "\E671";
    }
  }
}

.a-title-v {
  float: left;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  font-size: 12px;
  line-height: 30px;
  color: c(text-5);
}

.asian-comp-row-v .a-title-v {
  padding: 0 0 0 30px;
}

.asian-comp-row-v > span.flag-view-m {
  top: 5px;
}

.asian-competition-comp-n > span {
  top: 165px;
}

.game-date-view-m.asian-v {
  //// top: 167px;
  // //top: 119px;
  // top: 129px;
}

.asian-open-v {
  float: left;
  width: 100%;
  display: block;
}

.asian-view .select-sport-title-m {
  height: 2px;
}

// start back to top part
.asian-view .back-to-top-m {
  padding: 10px 10px 16px 10px;
  text-align: center;
}

.back-to-top-m.hide-footer-back-to-top {
  opacity: 1;
}

//right or left menu open stucky part
.left-menu-opened, .rightMenu {
  .asian-comp-row-v, .game-date-view-m, .title-game-type-box {
    position: inherit;
    top: inherit;
  }
}

.asian-view-games-list-wrapper-m .date-list-games-m {
  margin: 0;
}

.asian-competitions-filter-wrapper .a-competition-contain > ul:last-child {
  padding-bottom: 30px;
}

//for tablet 1x2
@media screen and (min-width: 500px) {
  .asian-view .game-date-view-m > ul {
    width: inherit;
  }
}

.favorite-game-list .game-date-view-m.asian-v {
  top: 129px;
}

.favorite-game-list .asian-comp-row-v.second-c-p {
  top: 98px;
}

.asian-competition-t-v.media li span.tv {
  padding: 0 30px 0 0;
  position: relative;
}

.asian-competition-t-v.media li span.tv:after {
  right: 10px;
}

.check-m.child-selected:before {
  content: "\ea0b";
  font: 12px/20px icomoon;
  color: c(skin-11);
  text-align: center;
}

.asian-view p.select-contain-m.match-a {
  width: calc(100% - 34px);
  float: left;
  margin: 0 0 0 34px;
}

.asian-view .fav-star-m {
  top: 0;
  height: 24px;
  line-height: 24px;
}

@media screen and (min-width: 980px) {
  .sw-contain-b .left-top-nav {
    display: none;
  }
  .asian-view .content-m {
    -webkit-overflow-scrolling: auto;
    height: 100%;
  }
}

.content-m .draw-asian .single-coefficient-m.blockedEvent:first-child:after {
  font: 16px/29px icomoon;
}

//sticky support
@supports (position: sticky)or (position: -webkit-sticky) {
  .asian-competition-t-v {
    position: sticky;
    position: -webkit-sticky;
    z-index: 222;
    top: 86px;
  }
  .asian-comp-row-v {
    position: sticky;
    position: -webkit-sticky;
    z-index: 222;
    top: 136px;
    &.second-c-p {
      top: 86px;
      z-index: 199;
    }
  }
}

// add future competition styles
.icon-view-u-m.icon-stopwatch {
  content: '\e6e2';
}

.asian-view-wrapper.future .asian-filter-picker ul, .asian-view-wrapper.future .asian-filter-picker ul li {
  width: auto;
}

.asian-view-wrapper.future .title-row-u-m > p > span {
  padding: 0;
}

.asian-view-wrapper.future .asian-filter-picker ul li:last-child {
  // box-shadow: 1px 0 0 rgba(255,255,255,0.09) inset;
  border-left:1px solid c(skin-8);
}

.asian-view-wrapper .asian-filter-picker ul li {
  width: 50%;
}

.asian-view-wrapper .asian-filter-picker ul {
  width: 100%;
}

.asian-view-wrapper.future .asian-filter-picker::-webkit-scrollbar {
  display: none;
  width: 0;
}

.odd-stake-info-m.border-none {
  border-bottom: none;
}

.single-game-list-item-m {
  float: left;
  width: 100%;
  height: 60px;
  background: c(skin-12);
  margin: 1px 0;
  overflow-x: scroll;
  overflow-y: hidden;
}

.single-game-list-item-m:last-child {
  margin-bottom: 0;
}

.new-sticker-view-live-indicator {
  text-transform: none;
  display: inline-block;
  vertical-align: top;
  background: c(hero);
  font-style: normal;
  font: 10px/14px $font-f;
  padding: 0 4px;
  top: 5px;
  border-radius: 2px;
  color: c(text-1);
  margin: 0 0 0 10px;
  box-shadow: 1px 2px 0 rgba(0, 0, 0, 0.15);
}

.new-sticker-view-game-number {
  display: inline-block;
  vertical-align: top;
  background: c(hero);
  font-style: normal;
  font: 10px/14px $font-f;
  padding: 2px 4px;
  border-radius: 2px;
  color: c(text-1);
  margin: 0 0 0 10px;
  box-shadow: 1px 2px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  top: -30px;
  right: 0;
  height: 16px !important;
}

.for-live.new-sticker-view-game-number {
  position: static;
}

.single-game-list-item-m::-webkit-scrollbar {
  display: none;
  width: 0;
}

.game-information-m > ul {
  float: left;
  padding: 0;
  font-size: 0;
  width: calc(100% + 56px);
  height: 60px;
}

.game-information-m > ul > li {
  width: 52px;
  float: left;
  padding: 0;
  margin: 0;
  color: c(text-12);
  height: 60px;
  background: c(skin-12);
  box-shadow: 0 1px 0 c(text-16, .15) inset;
}

.game-information-m > ul > li.factor-m {
  font-size: 12px;
  line-height: 60px;
  width: 56px;
  background: c(skin-12);
  color: #c3953c;
  text-align: center;
  position: relative;
}

.game-information-m > ul > li.teams-name-info-m {
  width: 100%;
  background: c(skin-11);
  box-shadow: 0 1px 0 rgba(123, 129, 145, 0.15) inset;
}

.game-information-m > ul > li:first-child:nth-last-child(4) {
  width: calc(100% - 169px);
}

.game-information-m > ul > li:first-child:nth-last-child(2) {
  width: calc(100% - 56px);
}

.game-information-m > ul > li.fav-game-icon-m {
  width: 56px;
  background: c(skin-11);
  height: 60px;
  box-shadow: 0 1px 0 c(text-16, .15) inset;
  text-align: center;
  color: #131724;
  font: 24px/60px icomoon;
  transition: background 240ms, color 240ms;
}

.game-information-m > ul > li.fav-game-icon-m.active {
  background: c(favorite);
  color: c(favorite-hover);
}

.game-information-m > ul > li.fav-game-icon-m:before {
  content: "\f005";
  width: 100%;
  display: block;
  text-align: center;
}

.game-info-mini-m {
  float: left;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  text-align: left;
  display: block;
}
.game-info-mini-m > p {
  float: left;
  width: 100%;
  font-size: 12px;
  line-height: 15px;
  color: c(text-3);
  padding: 5px 0 0;
  display: table;
  height: 19px;
}
.e-game-info-mini-m > p {
  color: c(text-3);
}
.single-team > .game-info-mini-m > p {
  height: 38px;
}

.team-name-m-box {
  display: table-cell;
  vertical-align: top;
  position: relative;
  width: 100%;
  height: 100%;
}

.game-score-live-m {
  display: table-cell;
  height: 100%;
  text-align: right;
  padding: 0 0 0 5px;
}

.team-name-m-box > i {
  font-style: normal;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.single-team > .game-info-mini-m > p > .team-name-m-box > i {
  white-space: normal;
}

.game-score-live-m > i {
  font-style: normal;
  color: c(accent);
  white-space: nowrap;
}

.time-markets-count-m {
  float: left;
  width: 100%;
  padding: 4px 0 0;
  font-size: 12px;
  line-height: 14px;
  position: relative;
}

.time-markets-count-m > i {
  font-style: normal;
  display: inline-block;
  vertical-align: top;
  height: 14px;
}

.time-markets-count-m > .icons-game-info-m {
  position: relative;
  top: 1px;
}

.time-view-game-m {
  float: left;
  margin: 0 3px 0 0;
  color: c(text-6);
}

.blocked > .teams-name-info-m > .game-info-mini-m > .time-markets-count-m > .time-view-game-m:after,
.blocked .coefficient-game-view > ul > li > .single-coefficient-m > span:before {
  font: 10px/14px icomoon;
  content: "\e68f";
  padding: 0 0 0 5px;
  color: c(text-3);
}

.blocked > .all-markets-view-m > .singe-market-view-m .coefficient-game-view > ul > li > .single-coefficient-m,
.match-of-day-m.blocked > .coefficient-game-view > ul > li > .single-coefficient-m {
  pointer-events: none;
}

.blocked .coefficient-game-view > ul > li > .single-coefficient-m > span:before {
  font-size: 12px;
  color: c(text-6);
}

.blocked .coefficient-game-view > ul > li > .single-coefficient-m > span > i {
  display: none;
}

.game-information-m > ul.blocked > li.factor-m:before {
  font: 16px/60px icomoon;
  content: "\e68f";
  color: c(text-6);
}

.markets-count-view-m {
  float: right;
  color: c(text-6);
  position: relative;
  padding: 0 10px 0 0;
  text-align: right;
}

.markets-count-view-m:before {
  font: 16px/14px icomoon;
  position: absolute;
  right: -5px;
  top: 0;
  content: "\e672";
}

.b-row-view-m {
  font-size: 10px;
  font-weight: normal;
}

.content-m .single-coefficient-m {
  &.blockedEvent:first-child {
    background: c(skin-12);
    pointer-events: none;
    > span > i:first-child {
      display: none;
    }
    &:after {
      font: 16px/40px icomoon;
      content: "\e68f";
      color: c(text-6);
    }
  }
  &.closedEvent {
    pointer-events: none;
  }
}

.coefficient-game-view ul li .single-coefficient-m.blockedEvent:after {
  position: relative;
  right: 10px;
}

// Prematch styles
.date-list-games-m {
  float: left;
  width: 100%;
  margin: 0 0 4px;
}

.game-date-view-m,
.list-games-m {
  float: left;
  width: 100%;
}

.game-date-view-m {
  float: left;
  width: 100%;
  height: 25px;
  background: hsla(0,0%,97%,.1);
  box-shadow: 0 1px 0 rgba(123, 129, 145, 0.15) inset;
}

.game-date-view-m > ul {
  float: left;
  width: 100%;
  display: table;
}
.game-date-view-m > ul > li {
  display: table-cell;
  padding: 1px 0 0;
  width: 56px;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  color: c(text-3);
}

.select-contain-m > select {
  color: c(text-3);
}

.game-date-view-m > ul > li:first-child {
  width: calc(100% - 182px);
  text-align: left;
  color: c(text-6);
  padding: 0;
  position: relative;
}

.game-date-view-m > ul > li:first-child > p {
  width: 100%;
  top: 0;
  left: 0;
  padding: 1px 10px 0;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.game-date-view-m > ul > li:first-child > p.live-game-competition-m {
  padding-left: 40px;
}

// Time filter
.main-wrapper:not(.integration-view) .time-filter-m.live-f-view-m,
.main-wrapper:not(.integration-view) .asian-view .time-filter-m {
  position: sticky;
  position: -webkit-sticky;
  top: 48px;
}

.time-filter-m {
  float: left;
  width: 100%;
  margin: 0 0 1px;
  overflow: visible;
  background: c(skin-7);
  box-shadow: 0 1px 0 c(text-16, .15) inset;
}

.time-filter-m > ul {
  position: relative;
  float: left;
  display: table;
  width: auto;
  min-width: 100%;
  padding: 0 7px;
  height: 44px;
}

.game-markets-f > ul,
.integration-view .time-filter-m > ul {
  padding: 0 7px;
}

//
.game-information-m > ul > li.factor-m > .single-coefficient-m {
  display: table;
  width: 100%;
  height: 60px;
  line-height: 60px;
  box-shadow: 0 1px 0 rgba(123, 129, 145, 0.15) inset,
  1px 0 0 c(skin-15) inset;
}

.left-top-nav:before, .right-top-nav:after {
  line-height: 38px;
}

.sport-icon-m.favorites:before {
  font: 28px/32px icomoon;
  color: #c3953c;
  content: "\F005";
}

.game-markets-f > ul,
.integration-view .time-filter-m > ul {
  padding: 0;
}

.team-name-m-box {
  vertical-align: middle;
}

.teams-name-info-m {
  &.blockedEvent {
    pointer-events: none;
    .e-factor-m span > i:first-child {
      display: none;
    }
    .e-factor-m span {
      &:after {
        font: 16px/40px icomoon;
        content: "\e68f";
      }
    }
  }
  &.closedEvent {
    pointer-events: none;
  }
}

.e-single-coefficient-m {
  &.blockedEvent:first-child {
    background: c(skin-12);
    pointer-events: none;
    > span > i,
    > span > b {
      display: none;
    }
    &:after {
      font: 16px/40px icomoon;
      content: "\e68f";
      color: c(text-6);
    }
  }
  &.closedEvent {
    pointer-events: none;
  }
}

.asian-competitions-filter-wrapper {
  width: 100%;
  position: static;
  float: left;
  background: c(skin-12);
}

.a-competition-contain {
  float: left;
  width: 100%;
}

.a-competition-contain.order-filter-a {
  padding: 0 10px;
}

.a-competition-contain {
  float: left;
  width: 100%;
  &.time-filter-a {
    padding: 0 10px;
    > .check-m {
      height: 37px;
      box-shadow: 0 1px 0 c(text-12) inset;
      &:first-child {
        box-shadow: none;
      }
    }
  }
  > ul {
    float: left;
    width: 100%;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 10px;
      width: calc(100% - 20px);
      height: 1px;
      background: c(skin-12);
      bottom: 0;
    }
    > li {
      float: left;
      width: 100%;
      &.second-level-f {
        background: c(skin-12);
        padding: 0 30px;
        display: none;
        > .check-m {
          height: 37px;
          box-shadow: 0 1px 0 c(text-11) inset;
          &:first-child {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.check-m {
  float: left;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  line-height: 36px;
  padding: 0 0 0 30px;
  color: sc(skin-12, .8);
  position: relative;
  z-index: 22;
}

.check-m.selected:after, .check-m:before {
  display: block;
  position: absolute;
  left: 0;
  top: 8px;
  width: 20px;
  height: 20px;
}

.check-m:before {
  content: "";
  background: c(skin-14);
  border-radius: 3px;
}

.title-row-u-m.active > .icon-view-u-m, .title-row-u-m.active > p {
  color: sc(skin-12, .7);
}

.check-m.selected:after {
  font: 12px/20px icomoon;
  content: "T";
  text-align: center;
  color: sc(skin-14, .6);
}

.check-m.selected:after, .check-m:before {
  display: block;
  position: absolute;
  left: 0;
  top: 8px;
  width: 20px;
  height: 20px;
}

.asian-view-filters-wrapper-m .import-view-container {
  position: fixed;
  left: 0;
}

.save-cancel-result-m ul {
  display: table;
  width: 100%;
  table-layout: fixed;
  padding: 0 10px;
}

.asian-view-filters-wrapper-m .save-cancel-result-m ul li {
  display: table-cell;
  padding: 0 5px 0 0;
  width: 50%;
}

.expandable {
  float: left;
  width: 100%;
  position: relative;
  height: 37px;
  padding: 0 36px 0 10px;
}

.expandable:before {
  content: "";
  display: block;
  position: absolute;
  left: 10px;
  width: calc(100% - 20px);
  height: 1px;
  background: sc(skin-12, .6);
  bottom: 0;
}

.a-competition-contain .arrow-view-m {
  width: 36px;
  height: 36px;
  line-height: 28px;
  z-index: 111;
  top: 0;
  position: absolute;
  right: 0;
  font: 20px/40px icomoon;
  text-align: center;
  color: #575757;
}

.asian-competition-t-v.media li span.tv:after,
.european-competition-t-v.media span.tv:after {
  content: "\E995";
  position: relative;
  right: auto;
  top: auto;
  display: inline-block;
  font: 12px/12px Icomoon;
  color: hsla(0, 0%, 100%, .6);
  vertical-align: middle;
}


.asian-competition-t-v.media ul > li {
  line-height: 20px;
  text-align: left;
  position: relative;
  padding: 0 5px 0 0;
  display: table-cell;
  vertical-align: top;
  white-space: nowrap;
  width: 1%;
}

.asian-competition-t-v.media li span.tv,
.european-competition-t-v.media span.tv {
  padding: 0;
  margin-right: 5px;
}

.asian-competition-t-v.media li a {
  text-decoration: none;
  font-size: 11px;
}

.asian-competition-t-v {
  padding: 0;
}
.all-bets-settings-row-m > ul > li.cleared-all-box {
  width: 100px;
  float: right;
  .clear-all-m {
    float: right !important;
  }
}
.asian-competition-t-v.media,
.esport .game-date-view-m {
  background: #f3f1f2;
}

.asian-open-v .e-single-game-wrapper-a .list-games-m {
  padding: 10px 10px 0;
}

.asian-competition-t-v {
  height: 36px;
}

.asian-competition-t-v.media li:last-child span {
  padding: 0 0 0 2px;
}

.e-single-game-wrapper-a .game-date-view-m.asian-v {
  height: 26px;
  float: left;
  width: 100%;
}

.e-single-game-wrapper-a .game-date-view-m > ul {
  padding: 2px 0;
  display: block;
}

.esport .fav-star-m {
  position: absolute;
  left: 0;
  font: 16px/34px icomoon;
  text-align: center;
  width: 24px;
  height: 24px;
  line-height: 24px;
  z-index: 444;
  color: #969696;
}

.asian-view p.select-contain-m.match-a {
  width: calc(100% - 34px);
  float: left;
  margin: 0 0 0 34px;
}

.esport .game-date-view-m > ul > li:first-child p {
  padding-left: 24px;
}

.fav-star-m:before {
  font-size: 16px;
  color: rgba(160, 160, 160, .6);
  content: "\F005";
}

.esport .arrow-u-m:before,
.esport .expandable.opened > .arrow-view-m:before,
.esport .expandable > .arrow-view-m:before,
.esport .select-contain-m:before {
  font: 16px/20px icomoon;
  content: "\E671";
  color: #969696;
}

.e-single-game-wrapper-a .game-date-view-m > ul > li {
  max-width: inherit;
}

.sport-icon-m {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 24px;
  height: 38px;
  text-align: center;
  padding: 6px 0 0;
  color: #969696;
  transform: translateZ(0);
}

.asian-competition-t-v.media,
.esport .game-date-view-m {
  background: c(skin-12, .1);
}

.asian-competition-t-v.media li:last-child span:last-child:after {
  content: "";
}

.e-single-game-wrapper-a .list-games-m .game-date-view-m.asian-v {
  background: none;
}

//new odds type

.new-odds-type {
  display: table;
  width: 100%;
  table-layout: fixed;
  height: 40px;
  border-collapse: separate;
  border-spacing: 2px;
  > span {
    display: table-cell;
    padding: 0 10px;
    font-size: 12px;
    background-color: c(skin-8, .9);
    color: c(text-1);
    border-radius: 5px;
    vertical-align: middle;
    &.teams-name-info-m {
      float: none;
      width: 25%;
      text-align: center;
      color: #c3953c;
      &.active {
        color: c(text-1);
        background: c(hero);
      }
    }
  }
}
.select-sport-title-m {
  &.favorites {
    float: left;
    width: 100%;
    display: table;
    padding: 0 10px;
    > h2, > span {
      display: table-cell;
      line-height: 34px;
      font-size: 14px;
    }
    > h2 {
      font-weight: 400;
      vertical-align: top;
      padding: 0;
      position: relative;
      width: 100%;
      height: 34px;
      text-align: left;
    }
    > span {
      text-align: right;
      height: 100%;
      white-space: nowrap;
    }
  }
}
.right-menu-close-icon {
  + div {
    > .import-view-container {
      margin-top: 15px;
    }
  }
}
.title-row-u-m {
  box-shadow: none !important;
  .fav-star-m {
    &.active {
      background: none;
    }
  }
}

.radio-form-item {
  float: left;
  width: 100%;
  margin: 0 0 10px;
  > label {
    float: left;
    width: 100%;
    color: c(text-6);
    font-size: 13px;
    line-height: 16px;
    padding: 5px 0 5px 20px;
    position: relative;
    min-height: 20px;
    margin: 0 0 5px;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 5px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background:c(skin-6);
      z-index: 10;
    }
    > input {
      position: absolute;
      left: -9999em;
      opacity: 0;
      &:checked {
        + span:before {
          content: "";
          display: block;
          position: absolute;
          left: 5px;
          top: 10px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: c(skin-12);
          z-index: 21;
        }
      }
    }
  }
}

.superbet-container-m {
  background: c(skin-16, .3);
  .superbet-wrapper {
    background: c(skin-16, .7);
    .switcher-contain {
      background: c(skin-16, .2);
      &.on {
        background: c(hero);
      }
    }
    .bet-kind-container {
      border: none;
      &:after {
        display: none;
      }
      > p {
        box-shadow: none;
      }
    }
  }
  .superbet-icon, .freebet-icon {
    &:before {
      color: #e4e2e5;
      background: -webkit-linear-gradient(#afbec4, #e2dfe4);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .freebet-text {
    font-weight: bold;
    color: #c2c5cd;
  }
  .text-info-kind-bet {
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
    > p {
      color: sc(skin-16);
    }
  }
  .info-icon-k-bet.active {
    color: sc(skin-16);
  }
  .free-bet-container {
    box-shadow: none;
  }
  .radio-b {
    li {
      color: #c2c5cd;
      &:last-child {
        padding: 0 0 5px;
      }
    }
  }
  .radio-form-item {
    &, label {
      margin: 0;
    }
    > label {
      &:before {
        background: #c2c5cd;
      }
      span {
        color: #c2c5cd;
      }
    }
  }
  .free-bet-open-view {
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
    p {
      color: #c2c5cd;
    }
  }
}
[dir='rtl'] &{
  .favorite-star {
    right: auto !important;
    left: 0 !important;
  }
  .arrow-u-m {
    left: 10px;
    right: inherit !important;
  }
  .title-row-u-m > p > span {
    padding: 0 10px 0 28px;
  }

  .asian-view p.select-contain-m.match-a {
    float: left;
  }
  .select-contain-m:before {
    left: 0;
    right: auto;
  }
  .asian-view .singe-market-view-m .markets-closed-open-arrow-m {
    left: 35px;
    right: auto;
  }
  .game-view-title-contain-m {
    .favorite-star {
      left: auto !important;
      right: 0 !important;
    }
  }
  .all-bets-settings-row-m>ul>li {
    float: left !important;
  }
  .game-date-view-m.asian-v .select-contain-m > select {
    padding-right: 5px;
    padding-left: 23px;
  }
}
