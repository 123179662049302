@font-face {
  font-family: 'icomoon';
  src: url('./fonts/icomoon.eot');
  src: url('./fonts/icomoon.eot') format('embedded-opentype'),
    url('./fonts/icomoon.woff') format('woff'), url('./fonts/icomoon.ttf') format('truetype'),
    url('./fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
