@font-face {
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  src: local('mardoto-regular'),
  url('../arm/mardoto-regular-webfont.woff2') format('woff2'),
  url('../arm/mardoto-regular-webfont.woff') format('woff'),
  url('../arm/mardoto-regular-webfont.ttf') format('truetype');
  unicode-range: U+0530-058F;
}
//@font-face {
//  font-family: 'Roboto';
//  font-weight: normal;
//  font-style: normal;
//  src: local('arnamu'),
//  url('../arm/arnamu.woff2') format('woff2'),
//  url('../arm/arnamu.woff') format('woff'),
//  url('../arm/arnamu.ttf') format('truetype'),
//  url('../arm/arnamu.eot'),
//  url('../arm/arnamu.eot') format('embedded-opentype'),
//  url('../arm/arnamu.svg') format('svg');
//  unicode-range: U+0530-058F;
//}
//@font-face {
//  font-family: 'Roboto';
//  font-weight: bold;
//  font-style: normal;
//  src: local('arnamu'),
//  url('../arm/arnamu_bold.woff2') format('woff2'),
//  url('../arm/arnamu_bold.woff') format('woff'),
//  url('../arm/arnamu_bold.ttf') format('truetype'),
//  url('../arm/arnamu_bold.eot'),
//  url('../arm/arnamu_bold.eot') format('embedded-opentype'),
//  url('../arm/arnamu_bold.svg') format('svg');
//  unicode-range: U+0530-058F;
//}

@font-face {
  font-family: Roboto;
  font-weight: bold;
  font-style: normal;
  src: local('mardoto-bold'),
  url('../arm/mardoto-bold-webfont.woff2') format('woff2'),
  url('../arm/mardoto-bold-webfont.woff') format('woff'),
  url('../arm/mardoto-bold-webfont.ttf') format('truetype');
  unicode-range: U+0530-058F;
}

