.react-datepicker {
  $self-class: &;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  &-popper &__triangle,
  &__tether-element-attached-bottom &__triangle,
  &__year-read-view--down-arrow {
    margin-left: -8px;
    position: absolute;
  }
  &-popper &__triangle,
  &__tether-element-attached-bottom &__triangle,
  &__year-read-view--down-arrow {
    &,
    &:before {
      box-sizing: content-box;
      position: absolute;
      border: 8px solid transparent;
      height: 0;
      width: 1px;
    }
    &:before {
      content: '';
      z-index: -1;
      border-width: 8px;
      left: -8px;
      border-bottom-color: #aeaeae;
    }
  }
  &-popper {
    z-index: 99;
    #{$self-class}__triangle {
      top: 0;
      margin-top: -8px;
      &,
      &:before {
        border-top: none;
        border-bottom-color: #f0f0f0;
      }
      &:before {
        top: -1px;
        border-bottom-color: #aeaeae;
      }
    }
  }
  &__tether-element-attached-bottom &__triangle,
  &__year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
    &,
    &:before {
      border-bottom: none;
      border-top-color: #fff;
    }
    &:before {
      bottom: -1px;
      border-top-color: #aeaeae;
    }
  }
  &__triangle {
    position: absolute;
    left: 50px;
  }
  &__tether-element-attached-bottom {
    &#{$self-class}__tether-element {
      margin-top: -20px;
    }
  }
  &__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
    &__dropdown--select {
      margin-top: -16px;
    }
  }
  &__year-dropdown-container--select,
  &__month-dropdown-container--select {
    display: inline-block;
    margin: 0 2px;
  }
  &__current-month {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
    &--hasYearDropdown {
      margin-bottom: 16px;
    }
  }
  &__navigation {
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    &--next {
      right: 10px;
      border-left-color: #ccc;
      &:hover {
        border-left-color: #b3b3b3;
      }
    }
    &--previous {
      left: 10px;
      border-right-color: #ccc;
      &:hover {
        border-right-color: #b3b3b3;
      }
    }
    &--years {
      position: relative;
      top: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;
      &-previous {
        top: 4px;
        border-top-color: #ccc;
        &:hover {
          border-top-color: #b3b3b3;
        }
      }
      &-upcoming {
        top: -4px;
        border-bottom-color: #ccc;
        &:hover {
          border-bottom-color: #b3b3b3;
        }
      }
    }
  }
  &__month {
    margin: 0.4rem;
    text-align: center;
    &-container {
      display: inline;
      float: left;
    }
    &--selecting-range {
      #{$self-class}__day--in-range {
        &:not(#{$self-class}__day--in-selecting-range) {
          background-color: #f0f0f0;
          color: #000;
        }
      }
    }
  }
  &__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }
  &__day {
    &,
    &-name {
      color: #000;
      display: inline-block;
      width: 1.7rem;
      line-height: 1.7rem;
      text-align: center;
      margin: 0.166rem;
      cursor: pointer;
    }
    &:hover {
      border-radius: 0.3rem;
      background-color: #f0f0f0;
    }
    &--today {
      font-weight: bold;
    }
    &--highlighted {
      border-radius: 0.3rem;
      background-color: #3dcc4a;
      color: #fff;
      &:hover {
        background-color: #32be3f;
      }
    }
    &--selected,
    &--in-selecting-range,
    &--in-range {
      border-radius: 0.3rem;
      background-color: #216ba5;
      color: #fff;
      &:hover {
        background-color: #1d5d90;
      }
    }
    &--in-selecting-range {
      &:not(#{$self-class}__day--in-range) {
        background-color: rgba(33, 107, 165, 0.5);
      }
    }
    &--disabled {
      cursor: default;
      color: #ccc;
      &:hover {
        background-color: transparent;
      }
    }
  }
  &__year {
    &-read-view {
      width: 50%;
      left: 25%;
      position: absolute;
      bottom: 25px;
      border: 1px solid transparent;
      border-radius: 0.3rem;
      &:hover {
        cursor: pointer;
        #{$self-class}__year-read-view--down-arrow {
          border-top-color: #b3b3b3;
        }
      }
      &--down-arrow {
        border-top-color: #ccc;
        margin-bottom: 3px;
        left: 5px;
        top: 9px;
        position: relative;
        border-width: 0.45rem;
      }
      &--selected-year {
        right: 0.45rem;
        position: relative;
      }
      &-dropdown {
        background-color: #f0f0f0;
        position: absolute;
        width: 50%;
        left: 25%;
        top: 30px;
        text-align: center;
        border-radius: 0.3rem;
        border: 1px solid #aeaeae;
        &:hover {
          cursor: pointer;
        }
        &--scrollable {
          height: 150px;
          overflow-y: scroll;
        }
      }
    }
    &-option {
      line-height: 20px;
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      &:first-of-type {
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
      }
      &:last-of-type {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
      }
      &:hover {
        background-color: #ccc;
        #{$self-class}__navigation--years {
          &-upcoming {
            border-bottom-color: #b3b3b3;
          }
          &-previous {
            border-top-color: #b3b3b3;
          }
        }
      }
      &--selected {
        position: absolute;
        left: 30px;
      }
    }
  }
  &__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    height: 0;
    outline: 0;
    padding: 0;
    vertical-align: middle;
    &:after {
      background-color: #216ba5;
      border-radius: 50%;
      bottom: 0;
      box-sizing: border-box;
      color: #fff;
      content: '\00d7';
      cursor: pointer;
      font-size: 12px;
      height: 16px;
      width: 16px;
      line-height: 1;
      margin: -8px auto 0;
      padding: 2px;
      position: absolute;
      right: 7px;
      text-align: center;
      top: 50%;
    }
  }
  &__today-button {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
  }
  &__tether-element {
    z-index: 2147483647;
  }
  &-wrapper {
    #{$self-class}__input-container {
      float: left;
      width: 100%;
      position: relative;
      &:before {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 25px;
        height: 38px;
        font: 16px/38px icomoon;
        content: '\E919';
        color: #474d5b;
        z-index: 10;
      }
    }
  }
}
