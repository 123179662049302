.flag-view-m{
  position: absolute;
  display: block;
  left: 10px;
  top: 9px;
  width: 22px;
  height: 22px;
  max-width: 100%;
  background-image: url(../../../images/flags-sprite.png);
  background-size: 100%;
  //image-rendering: pixelated;
}
.icon-afghanistan { background-position: 0 0.35461%;  }
.icon-africa { background-position: 0 0.70922%;  }
.icon-aland { background-position: 0 1.06383%;  }
.icon-albania { background-position: 0 1.41844%;  }
.icon-alderney { background-position: 0 1.77305%;  }
.icon-algeria { background-position: 0 2.12766%;  }
.icon-americansamoa { background-position: 0 2.48227%;  }
.icon-andorra { background-position: 0 2.836879%;  }
.icon-angola { background-position: 0 3.191489%;  }
.icon-anguilla { background-position: 0 3.546099%;  }
.icon-antiguaandbarbuda { background-position: 0 3.900709%;  }
.icon-arableague { background-position: 0 4.255319%;  }
.icon-asean { background-position: 0 0%;  }
.icon-argentina { background-position: 0 4.609929%;  }
.icon-armenia { background-position: 0 4.964539%;  }
.icon-aruba { background-position: 0 5.319149%;  }
.icon-asia { background-position: 0 5.673759%;  }
.icon-australia { background-position: 0 6.028369%;  }
.icon-austria { background-position: 0 6.382979%;  }
.icon-azerbaijan { background-position: 0 6.737589%;  }
.icon-bahamas { background-position: 0 7.092199%;  }
.icon-bahrain { background-position: 0 7.446809%;  }
.icon-bangladesh { background-position: 0 7.801418%;  }
.icon-barbados { background-position: 0 8.156028%;  }
.icon-basquecountry { background-position: 0 8.510638%;  }
.icon-belarus { background-position: 0 8.865248%;  }
.icon-belgium { background-position: 0 9.219858%;  }
.icon-belize { background-position: 0 9.574468%;  }
.icon-benin { background-position: 0 9.929078%;  }
.icon-bermuda { background-position: 0 10.283688%;  }
.icon-bhutan { background-position: 0 10.638298%;  }
.icon-bolivia { background-position: 0 10.992908%;  }
.icon-bonairesinteustatiusandsaba { background-position: 0 11.347518%;  }
.icon-netherlands { background-position: 0 11.347518%;  }
.icon-bosniaherzegovina { background-position: 0 11.702128%;  }
.icon-bosniaandherzegovina { background-position: 0 11.702128%;  }
.icon-botswana { background-position: 0 12.056738%;  }
.icon-bouvet { background-position: 0 12.411348%;  }
.icon-brazil { background-position: 0 12.765957%;  }
.icon-britishindianoceanterritory { background-position: 0 13.120567%;  }
.icon-brunei { background-position: 0 13.475177%;  }
.icon-bruneidarussalam { background-position: 0 13.475177%;  }
.icon-bulgaria { background-position: 0 13.829787%;  }
.icon-burkinafaso { background-position: 0 14.184397%;  }
.icon-burundi { background-position: 0 14.539007%;  }
.icon-caricom { background-position: 0 14.893617%;  }
.icon-cis { background-position: 0 15.248227%;  }
.icon-cambodja { background-position: 0 15.602837%;  }
.icon-cambodia { background-position: 0 15.602837%;  }
.icon-cameroon { background-position: 0 15.957447%;  }
.icon-canada { background-position: 0 16.312057%;  }
.icon-capeverde { background-position: 0 16.666667%;  }
.icon-catalonia { background-position: 0 17.021277%;  }
.icon-caymanislands { background-position: 0 17.375887%;  }
.icon-centralafricanrepublic { background-position: 0 17.730496%;  }
.icon-chad { background-position: 0 18.085106%;  }
.icon-chile { background-position: 0 18.439716%;  }
.icon-china { background-position: 0 18.794326%;  }
.icon-christmas { background-position: 0 19.148936%;  }
.icon-cocoskeeling { background-position: 0 19.503546%;  }
.icon-colombia { background-position: 0 19.858156%;  }
.icon-commonwealth { background-position: 0 20.212766%;  }
.icon-comoros { background-position: 0 20.567376%;  }
.icon-congobrazzaville { background-position: 0 20.921986%;  }
.icon-republicofthecongo { background-position: 0 20.921986%;  }
.icon-congokinshasa { background-position: 0 21.276596%;  }
.icon-congodemocraticrepublicofthezaire { background-position: 0 21.276596%;  }
.icon-cookislands { background-position: 0 21.631206%;  }
.icon-costarica, .icon-costa.rica { background-position: 0 21.985816%;  }
.icon-cotedivoire { background-position: 0 22.340426%;  }
.icon-coted { background-position: 0 22.340426%;  }
.icon-croatia { background-position: 0 22.695035%;  }
.icon-cuba { background-position: 0 23.049645%;  }
.icon-curacao { background-position: 0 23.404255%;  }
.icon-cyprus { background-position: 0 23.758865%;  }
.icon-czechrepublic { background-position: 0 24.113475%;  }
.icon-denmark { background-position: 0 24.468085%;  }
.icon-djibouti { background-position: 0 24.822695%;  }
.icon-dominica { background-position: 0 25.177305%;  }
.icon-dominicanrepublic { background-position: 0 25.531915%;  }
.icon-ecuador { background-position: 0 25.886525%;  }
.icon-egypt { background-position: 0 26.241135%;  }
.icon-elsalvador { background-position: 0 26.595745%;  }
.icon-salvador { background-position: 0 26.595745%;  }
.icon-england { background-position: 0 26.950355%;  }
.icon-equatorialguinea { background-position: 0 27.304965%;  }
.icon-eritrea { background-position: 0 27.659574%;  }
.icon-estonia { background-position: 0 28.014184%;  }
.icon-ethiopia { background-position: 0 28.368794%;  }
.icon-europe { background-position: 0 28.723404%;  }
.icon-fao { background-position: 0 29.078014%;  }
.icon-falkland { background-position: 0 29.432624%;  }
.icon-faroes { background-position: 0 29.787234%;  }
.icon-faroeislands { background-position: 0 29.787234%;  }
.icon-fiji { background-position: 0 30.141844%;  }
.icon-finland { background-position: 0 30.496454%;  }
.icon-france { background-position: 0 30.851064%;  }
.icon-french-guiana { background-position: 0 31.205674%;  }
.icon-frenchguiana { background-position: 0 31.205674%;  }
.icon-frenchsouthernandantarcticlands { background-position: 0 31.560284%;  }
.icon-gabon { background-position: 0 31.914894%;  }
.icon-galicia { background-position: 0 32.269504%;  }
.icon-gambia { background-position: 0 32.624113%;  }
.icon-georgia { background-position: 0 32.978723%;  }
.icon-germany { background-position: 0 33.333333%;  }
.icon-ghana { background-position: 0 33.687943%;  }
.icon-gibraltar { background-position: 0 34.042553%;  }
.icon-greece { background-position: 0 34.397163%;  }
.icon-greenland { background-position: 0 34.751773%;  }
.icon-grenada { background-position: 0 35.106383%;  }
.icon-guadeloupe { background-position: 0 35.460993%;  }
.icon-guam { background-position: 0 35.815603%;  }
.icon-guatemala { background-position: 0 36.170213%;  }
.icon-guernsey { background-position: 0 36.524823%;  }
.icon-guinea { background-position: 0 36.879433%;  }
.icon-guineabissau { background-position: 0 37.234043%;  }
.icon-guinea-bissau { background-position: 0 37.234043%;  }
.icon-guyana { background-position: 0 37.588652%;  }
.icon-haiti { background-position: 0 37.943262%;  }
.icon-republicofhaiti { background-position: 0 37.943262%;  }
.icon-heardislandandmcdonald { background-position: 0 38.297872%;  }
.icon-honduras { background-position: 0 38.652482%;  }
.icon-hongkong { background-position: 0 39.007092%;  }
.icon-hungary { background-position: 0 39.361702%;  }
.icon-iaea { background-position: 0 39.716312%;  }
.icon-iho { background-position: 0 40.070922%;  }
.icon-iceland { background-position: 0 40.425532%;  }
.icon-india { background-position: 0 40.780142%;  }
.icon-indonezia { background-position: 0 41.134752%;  }
.icon-indonesia { background-position: 0 41.134752%;  }
.icon-international { background-position: 0 41.489362%;  }
.icon-iran { background-position: 0 41.843972%;  }
.icon-iraq { background-position: 0 42.198582%;  }
.icon-kurdish { background-position: 0 42.198582%;  }
.icon-ireland { background-position: 0 42.553191%;  }
.icon-islamicconference { background-position: 0 42.907801%;  }
.icon-isleofman { background-position: 0 43.262411%;  }
.icon-israel { background-position: 0 43.617021%;  }
.icon-italy { background-position: 0 43.971631%;  }
.icon-jamaica { background-position: 0 44.326241%;  }
.icon-japan { background-position: 0 44.680851%;  }
.icon-jersey { background-position: 0 45.035461%;  }
.icon-jordan { background-position: 0 45.390071%;  }
.icon-kazakhstan { background-position: 0 45.744681%;  }
.icon-kenya { background-position: 0 46.099291%;  }
.icon-swahili { background-position: 0 46.099291%;  }
.icon-kiribati { background-position: 0 46.453901%;  }
.icon-kosovo { background-position: 0 46.808511%;  }
.icon-kuwait { background-position: 0 47.163121%;  }
.icon-kyrgyzstan { background-position: 0 47.51773%;  }
.icon-laos { background-position: 0 47.87234%;  }
.icon-latvia { background-position: 0 48.22695%;  }
.icon-lebanon { background-position: 0 48.58156%;  }
.icon-lesotho { background-position: 0 48.93617%;  }
.icon-usa {background-position: 0 49.29078%; }
.icon-liberia { background-position: 0 49.29078%;  }
.icon-libya { background-position: 0 49.64539%;  }
.icon-liechtenshein { background-position: 0 50%;  }
.icon-liechtenstein { background-position: 0 50%;  }
.icon-lithuania { background-position: 0 50.35461%;  }
.icon-luxembourg { background-position: 0 50.70922%;  }
.icon-luxemburg { background-position: 0 50.70922%;  }
.icon-macao { background-position: 0 51.06383%;  }
.icon-macau { background-position: 0 51.06383%;  }
.icon-macedonia { background-position: 0 51.41844%;  }
.icon-madagascar { background-position: 0 51.77305%;  }
.icon-malawi { background-position: 0 52.12766%;  }
.icon-malaysia { background-position: 0 52.48227%;  }
.icon-maldives { background-position: 0 52.836879%;  }
.icon-mali { background-position: 0 53.191489%;  }
.icon-malta { background-position: 0 53.546099%;  }
.icon-marshallislands { background-position: 0 53.900709%;  }
.icon-martinique { background-position: 0 54.255319%;  }
.icon-mauritania { background-position: 0 54.609929%;  }
.icon-mauritius { background-position: 0 54.964539%;  }
.icon-mayotte { background-position: 0 55.319149%;  }
.icon-mexico { background-position: 0 55.673759%;  }
.icon-micronesia { background-position: 0 56.028369%;  }
.icon-federatedstatesofmicronesia { background-position: 0 56.028369%;  }
.icon-moldova { background-position: 0 56.382979%;  }
.icon-monaco { background-position: 0 56.737589%;  }
.icon-mongolia { background-position: 0 57.092199%;  }
.icon-montenegro { background-position: 0 57.446809%;  }
.icon-montserrat { background-position: 0 57.801418%;  }
.icon-morocco { background-position: 0 58.156028%;  }
.icon-mozambique { background-position: 0 58.510638%;  }
.icon-myanmar { background-position: 0 58.865248%;  }
.icon-nato { background-position: 0 59.219858%;  }
.icon-namibia { background-position: 0 59.574468%;  }
.icon-nauru { background-position: 0 59.929078%;  }
.icon-nepal { background-position: 0 60.283688%;  }
.icon-netherlandsantilles { background-position: 0 60.638298%;  }
.icon-newcaledonia { background-position: 0 60.992908%;  }
.icon-newzealand { background-position: 0 61.347518%;  }
.icon-nicaragua { background-position: 0 61.702128%;  }
.icon-niger { background-position: 0 62.056738%;  }
.icon-nigeria { background-position: 0 62.411348%;  }
.icon-niue { background-position: 0 62.765957%;  }
.icon-norfolk { background-position: 0 63.120567%;  }
.icon-northamerica { background-position: 0 63.475177%;  }
.icon-northkorea { background-position: 0 63.829787%;  }
.icon-dprk { background-position: 0 63.829787%;  }
.icon-northerncyprus { background-position: 0 64.184397%;  }
.icon-northernireland { background-position: 0 64.539007%;  }
.icon-northernmariana { background-position: 0 64.893617%;  }
.icon-norway { background-position: 0 65.248227%;  }
.icon-oas { background-position: 0 65.602837%;  }
.icon-opec { background-position: 0 65.957447%;  }
.icon-oceania { background-position: 0 66.312057%;  }
.icon-olimpics { background-position: 0 66.666667%;  }
.icon-oman { background-position: 0 67.021277%;  }
.icon-pakistan { background-position: 0 67.375887%;  }
.icon-palau { background-position: 0 67.730496%;  }
.icon-palestine { background-position: 0 68.085106%;  }
.icon-panama { background-position: 0 68.439716%;  }
.icon-papuanewguinea { background-position: 0 68.794326%;  }
.icon-paraguay { background-position: 0 69.148936%;  }
.icon-peru { background-position: 0 69.503546%;  }
.icon-philippines { background-position: 0 69.858156%;  }
.icon-pitcairn { background-position: 0 70.212766%;  }
.icon-poland { background-position: 0 70.567376%;  }
.icon-portugal { background-position: 0 70.921986%;  }
.icon-puertorico { background-position: 0 71.276596%;  }
.icon-qatar { background-position: 0 71.631206%;  }
.icon-quebec { background-position: 0 71.985816%;  }
.icon-redcross { background-position: 0 72.340426%;  }
.icon-reunion { background-position: 0 72.695035%;  }
.icon-romania { background-position: 0 73.049645%;  }
.icon-russia { background-position: 0 73.404255%;  }
.icon-rwanda { background-position: 0 73.758865%;  }
.icon-saintbarthelemy { background-position: 0 74.113475%;  }
.icon-sainthelena { background-position: 0 74.468085%;  }
.icon-saintlucia { background-position: 0 74.822695%;  }
.icon-saintmartin { background-position: 0 75.177305%;  }
.icon-saintpierreandmiquelon { background-position: 0 75.531915%;  }
.icon-samoa { background-position: 0 75.886525%;  }
.icon-sanmarino { background-position: 0 76.241135%;  }
.icon-saotomeandprincipe { background-position: 0 76.595745%;  }
.icon-saudiarabia, .icon-saudi.arabia { background-position: 0 76.950355%;  }
.icon-scotland { background-position: 0 77.304965%;  }
.icon-senegal { background-position: 0 77.659574%;  }
.icon-serbia { background-position: 0 78.014184%;  }
.icon-seychelles { background-position: 0 78.368794%;  }
.icon-sierraleone { background-position: 0 78.723404%;  }
.icon-singapore { background-position: 0 79.078014%;  }
.icon-sintmaarten { background-position: 0 79.432624%;  }
.icon-slovakia { background-position: 0 79.787234%;  }
.icon-slovenia { background-position: 0 80.141844%;  }
.icon-solomonislands { background-position: 0 80.496454%;  }
.icon-somalia { background-position: 0 80.851064%;  }
.icon-somaliland { background-position: 0 81.205674%;  }
.icon-southafrica { background-position: 0 81.560284%;  }
.icon-republicofsouthafrica { background-position: 0 81.560284%;  }
.icon-southamerica { background-position: 0 81.914894%;  }
.icon-southgeorgiaandsouthsandwich { background-position: 0 82.269504%;  }
.icon-southkorea, .icon-south.korea{ background-position: 0 82.624113%;  }
.icon-southossetia { background-position: 0 82.978723%;  }
.icon-southsudan { background-position: 0 83.333333%;  }
.icon-spain { background-position: 0 83.687943%;  }
.icon-srilanka { background-position: 0 84.042553%;  }
.icon-stkittsandnevis { background-position: 0 84.397163%;  }
.icon-saintkittsandnevis { background-position: 0 84.397163%;  }
.icon-stvincentandthegrenadines { background-position: 0 84.751773%;  }
.icon-saintvincentandthegrenadines { background-position: 0 84.751773%;  }
.icon-sudan { background-position: 0 85.106383%;  }
.icon-suriname { background-position: 0 85.460993%;  }
.icon-republicofsuriname { background-position: 0 85.460993%;  }
.icon-svalbardandjanmayen { background-position: 0 85.815603%;  }
.icon-swaziland { background-position: 0 86.170213%;  }
.icon-sweden { background-position: 0 86.524823%;  }
.icon-switzerland { background-position: 0 86.879433%;  }
.icon-syria { background-position: 0 87.234043%;  }
.icon-tahiti { background-position: 0 87.588652%;  }
.icon-taiwan { background-position: 0 87.943262%;  }
.icon-chinesetaipei { background-position: 0 87.943262%;  }
.icon-tajikistan { background-position: 0 88.297872%;  }
.icon-tadjikistan { background-position: 0 88.297872%;  }
.icon-tanzania { background-position: 0 88.652482%;  }
.icon-unitedrepublicoftanzania { background-position: 0 88.652482%;  }
.icon-thailand { background-position: 0 89.007092%;  }
.icon-timorleste { background-position: 0 89.361702%;  }
.icon-timor-leste { background-position: 0 89.361702%;  }
.icon-togo { background-position: 0 89.716312%;  }
.icon-tokelau { background-position: 0 90.070922%;  }
.icon-tonga { background-position: 0 90.425532%;  }
.icon-trinidadtobago { background-position: 0 90.780142%;  }
.icon-trinidadandtobago { background-position: 0 90.780142%;  }
.icon-tristandacunha { background-position: 0 91.134752%;  }
.icon-tunisia { background-position: 0 91.489362%;  }
.icon-turkey { background-position: 0 91.843972%;  }
.icon-turkmenistan { background-position: 0 92.198582%;  }
.icon-turksandcaicosislands { background-position: 0 92.553191%;  }
.icon-tuvalu { background-position: 0 92.907801%;  }
.icon-usa { background-position: 0 93.262411%;  }
.icon-america { background-position: 0 93.262411%;  }
.icon-uganda { background-position: 0 93.617021%;  }
.icon-ukraine { background-position: 0 93.971631%;  }
.icon-unitedarabemirates { background-position: 0 94.326241%;  }
.icon-uae { background-position: 0 94.326241%;  }
.icon-unitedkingdom { background-position: 0 94.680851%;  }
.icon-greatbritain { background-position: 0 94.680851%;  }
.icon-uruguay { background-position: 0 95.035461%;  }
.icon-uzbekistan { background-position: 0 95.390071%;  }
.icon-vanutau { background-position: 0 95.744681%;  }
.icon-vanuatu { background-position: 0 95.744681%;  }
.icon-vaticancity { background-position: 0 96.099291%;  }
.icon-venezuela { background-position: 0 96.453901%;  }
.icon-vietnam { background-position: 0 96.808511%;  }
.icon-virginislandsbritish { background-position: 0 97.163121%;  }
.icon-britishvirginislands { background-position: 0 97.163121%;  }
.icon-virginislandsus { background-position: 0 97.51773%;  }
.icon-unitedstatesvirginislands { background-position: 0 97.51773%;  }
.icon-wales { background-position: 0 97.87234%;  }
.icon-wallisandfutuna { background-position: 0 98.22695%;  }
.icon-westernsahara { background-position: 0 98.58156%;  }
.icon-world { background-position: 0 98.93617%;  }
.icon-yemen { background-position: 0 99.29078%;  }
.icon-zambia { background-position: 0 99.64539%;  }
.icon-zimbabwe { background-position: 0 100%;  }