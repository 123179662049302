@import 'assets/scss/skin';
//Date picker
.date-picker-wrapper {
  float: left;
  width: 100%;
  margin: 0 0 10px;
  > .react-datepicker__input-container {
    float: left;
    width: 100%;
    position: relative;
    &:before {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 25px;
      height: 38px;
      font: 16px/38px icomoon;
      content: "\e919";
      color: c(text-7);
      z-index: 10;
    }
    > input[type=text] {
      float: left;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid c(text-6);
      background: none;
      height: 38px;
      padding: 6px 10px;
      font-family: $font-f;
      font-size: 14px;
      color: c(text-6);
      transition: border 240ms;
      border-radius: inherit;
      box-shadow: none;
      position: relative;
      z-index: 20;
    }
    > input[type=text]:focus {
      border: 1px solid c(hero);
    }
    > input::-webkit-input-placeholder {
      opacity: 1;
      color: c(text-6);
    }
    > input::-moz-placeholder {
      opacity: 1;
      color: c(text-6);
    }
    > input:-ms-input-placeholder{
      opacity: 1;
      color: c(text-6);
    }
    > input:-moz-placeholder {
      opacity: 1;
      color: c(text-6);
    }
  }
}
.react-datepicker {
  $self-class: &;
  font-family: $font-f !important;
  background-color: c(skin-10) !important;
  border: 1px solid c(skin-15) !important;
  color: c(text-2) !important;
  &__header {
    background-color: c(skin-12) !important;
    border-bottom: 1px solid c(skin-15) !important;
  }
  &__day-name, &__day {
    color: c(text-6) !important;
  }
  &__day {
    &--selected {
      color: c(text-2) !important;
    }
  }
  &__current-month {
    color: c(text-2) !important;
    font-weight: normal !important;
  }
  &__tether-element-attached-bottom &__triangle,
  &__year-read-view--down-arrow {
    &, &:before {
      border-top-color: c(skin-14) !important;
    }
  }
  &__tether-element-attached-top {
    &__triangle {
      &, &:before {
        border-top: none;
        border-bottom-color: c(skin-15) !important;
      }
    }
  }
  &__navigation {
    &--previous {
      border-right-color: c(skin-6) !important;
    }

    &--next {
      border-left-color: c(skin-6) !important;
    }
  }
  &__year-dropdown-container--select,
  &__month-dropdown-container--select {
    position: relative;
    > select {
      float: left;
      width: auto;
      position: relative;
      z-index: 55;
      background: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid c(skin-6);
      height: 32px;
      box-sizing: border-box;
      color: c(text-5);
      font-family: $font-f;
      font-size: 12px;
      padding-left: 7px;
      padding-right: 36px;
      text-transform: uppercase;
      border-radius: inherit;
    }
    &:before {
      display: block;
      position: absolute;
      z-index: 10;
      font: 14px/18px icomoon;
      content: "\E670";
      right: 0;
      top: 7px;
      width: 26px;
      height: 18px;
      border-left: 1px solid c(skin-9);
      text-align: center;
      color: c(text-6);
    }
  }
  &__header__dropdown--select {
    margin-top: -6px !important;
  }
  &-popper {
    #{$self-class}__triangle {
      &:before {
        border-top: none;
        border-bottom-color: c(skin-14) !important;
      }

    }
  }
  &-popper {
    &[data-placement="top-start"] {
      top: -8px !important;
      #{$self-class}__triangle {
        bottom: 0 !important;
        top: auto !important;
      }
      > #{$self-class} {
        > #{$self-class}__triangle {
          border: none !important;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            z-index: 99;
            border-style: solid;
            border-width: 8px 10px 0 10px !important;
            border-color: c(skin-14) transparent transparent transparent !important;
          }
        }
      }
    }
    &[data-placement="bottom-start"] {
      top: 46px !important;
      > #{$self-class} {
        > #{$self-class}__triangle {
          border: none !important;
          &:before {
            top: 0 !important;
          }
        }
      }
    }
  }
}

