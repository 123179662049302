$hero-color: #cccccc;
$skin-color: #cccccc;
$text-color: #cccccc;
$brand-color-1: #cccccc;
$brand-color-2: #cccccc;
$coef-color: #cccccc;
$favorite-color: #cccccc;

@mixin scroll($scroll: scroll) {
  $border-radius: 10px;
  $width: 4px;
  $scroll-main-color: c(skin-8);
  $scroll-main-thumb-color: c(skin-5);

  overflow-y: $scroll;
  overflow-x: hidden;
  scrollbar-track-color: $scroll-main-color;
  scrollbar-shadow-color: $scroll-main-color;
  scrollbar-base-color: $scroll-main-thumb-color;
  scrollbar-arrow-color: $scroll-main-thumb-color;
  &::-webkit-scrollbar-track {
    border-radius: $border-radius;
  }
  &::-webkit-scrollbar {
    width: $width;
    background: $scroll-main-color;
    border-radius: $border-radius;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    background: $scroll-main-thumb-color;
  }
}

@function c($color, $opacity: 1) {
  @if $opacity == 1 {
    @return var(--#{$color});
  } @else {
    @return rgba(var(--#{$color}-rgb), $opacity);
  }
}
@function sc($color, $opacity: 1) {
  @if $opacity == 1 {
    @return var(--#{$color}-sc);
  } @else {
    @return rgba(var(--#{$color}-sc-rgb), $opacity);
  }
}
@function set-color($color, $color-dark: #000000, $color-light: #ffffff) {
  @if (lightness($color) > 50) {
    @return $color-dark;
  } @else {
    @return $color-light;
  }
}
$mobile-app-casino: url(images/get-app-casino.png) no-repeat 50% 50% / contain !default;

$font-f: 'Roboto', sans-serif !default;
