@font-face {
    font-family: 'Iran-f';
    src: url('../iran/IRAN_SemiBold.eot'),
    url('../iran/IRAN_SemiBold.eot') format('embedded-opentype'),
    url('../iran/IRAN_SemiBold.woff2') format('woff2'),
    url('../iran/IRAN_SemiBold.woff') format('woff'),
    url('../iran/IRAN_SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Iran-f';
    src: url('../iran/IRAN_SemiBold.eot'),
    url('../iran/IRAN_SemiBold.eot') format('embedded-opentype'),
    url('../iran/IRAN_SemiBold.woff2') format('woff2'),
    url('../iran/IRAN_SemiBold.woff') format('woff'),
    url('../iran/IRAN_SemiBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
//@font-face {
//    font-family: Parastoo;
//    font-weight: normal;
//    font-style: normal;
//    src: local('Parastoo-WOL'),
//    url('../iran/Without-Latin/Parastoo-WOL.woff') format('woff'),
//    url('../iran/Without-Latin/Parastoo-WOL.ttf') format('truetype'),
//    url('../iran/Without-Latin/Parastoo-WOL.eot');
//    unicode-range: U+0600-06FF;
//}
//@font-face {
//    font-family: Parastoo;
//    font-weight: bold;
//    font-style: normal;
//    src: local('Parastoo-Bold-WOL'),
//    url('../iran/Without-Latin/Parastoo-Bold-WOL.woff') format('woff'),
//    url('../iran/Without-Latin/Parastoo-Bold-WOL.ttf') format('truetype'),
//    url('../iran/Without-Latin/Parastoo-Bold-WOL.eot'),
//    url('../iran/Without-Latin/Parastoo-Bold-WOL.eot') format('embedded-opentype');
//    unicode-range: U+0600-06FF;
//}
@font-face {
    font-family: Parastoo;
    font-weight: bold;
    font-style: normal;
    src:
    url('../default/Parastoo/Parastoo-Bold-FD.woff') format('woff'),
    url('../default/Parastoo/Parastoo-Bold-FD.ttf') format('truetype'),
    url('../default/Parastoo/Parastoo-Bold-FD.eot');
}
@font-face {
    font-family: Parastoo;
    font-weight: bold;
    font-style: normal;
    src:
    url('../default/Parastoo/Parastoo-FD.woff') format('woff'),
    url('../default/Parastoo/Parastoo-FD.ttf') format('truetype'),
    url('../default/Parastoo/Parastoo-FD.eot');
}

@font-face {
  font-family: 'gilroy';
  src: url('../default/gilroy/Gilroy-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'futura';
    src: url('../default/futura/futura.otf');
    font-weight: normal;
    font-style: normal;
}

/*
@font-face {
    font-family: 'Gilroy-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy-Regular'), url('../../../../skins/playgorillagames.com/mobile/font/Gilroy-Regular.woff') format('woff');
}*/
